import React, {useEffect, useState} from 'react';
import {ApiAd} from '../../models/api/ad';
import {ApiDeal} from '../../models/api/deal';
import {AppState} from '../../redux';
import {buildAdData, buildContactUsData, buildDealData} from './data';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {EGeneralColors} from '../../models/enums/colors';
import {ERoles} from '../../models/enums/roles';
import {ETypeTaskText} from '../../models/enums/generals';
import {getBusinessById} from '../../services/api/businessAPI';
import {IApiContactUs} from '../../models/api/contactUs';
import {IBusinessData} from '../../models/api/business';
import {ICurrentSort} from '../../models/interfaces/table';
import {ITaskData} from '../../models/interfaces/taskData';
import {methodNames} from '../../constants/hubMethodNames';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {setCurrentLocation} from '../../redux/actions/userActions';
import {showPopup} from '../../redux/actions/uiActions';
import {TaskManagerTaskState} from '../../models/enums/taskManagerTaskState';
import {TaskManagerTaskStatus} from '../../models/enums/taskStatus';
import {taskTableColumns} from '../../constants/tableColumns';
import {useDispatch, useSelector} from 'react-redux';
import {useHubConnection} from '../../hooks/useHubConnection';
import {UserConfirmationRequestState} from '../../models/enums/userConfirmationRequestState';
import {useTaskList} from '../../hooks/useTaskList';
import {useTranslation} from 'react-i18next';
import {
  isHubConnectionAction,
  setSelectedVendorTask,
} from '../../redux/actions/taskActions';
import {
  TaskManagerTaskType,
  TaskReportType,
} from '../../models/enums/taskManagerTaskType';
import {
  ApprovalOperation,
  EColorsOperationBtns,
} from '../../models/enums/approval';
import {
  generalModalStyles,
  editModalStyles,
  confirmModalStyles,
} from '../../constants/styles';
import {
  IChangeUserConfirmationStateRequest,
  IContactUsStateRequest,
  IUpdateAdTaskRequest,
  IUpdateBusinessStateRequest,
  IUpdateDealTaskRequest,
  IUserToVerify,
} from '../../models/api/task';
import {
  getUserToVerify,
  updateAdTaskStatus,
  updateBusinessStatus,
  updateContactUsTaskStatus,
  updateDealTaskStatus,
  updateTaskStatus,
} from '../../services/api/taskAPI';
import Actions from './components/Actions';
import AppliedFilters from '../../components/HeaderContent/AppliedFilters';
import Approval from '../../components/Approval';
import ConfirmationModal from '../../components/ConfirmationModal';
import endpoints from '../../services/api/endpoints';
import formatDate from '../../utils/formatDate';
import HeaderContent from '../../components/HeaderContent';
import Indicators from '../../components/Indicators';
import LeftMenu from '../../components/LeftMenu';
import Modal from 'react-modal';
import NoResults from './components/NoResults';
import Pagination from '../../components/Pagination';
import ProfileEditAuthorization from '../../components/ProfileEditAuthorization';
import ProfileVerification from '../../components/ProfileVerification';
import RequestEdits from '../../components/RequestEdits';
import Table from '../../components/Table';
import Tag from '../../components/Tag';
import './styles.scss';
import DrawerMenu from '../../components/DrawerMenu';

const allowedSortBy = ['taskType', 'dateRequested', 'status'];

const TaskManagerPage = () => {
  // Hooks
  const dispatch = useDispatch();
  const {t} = useTranslation('taskManagement');
  const {user, tasks} = useSelector((state: AppState) => state);
  const userRoles = useSelector((state: AppState) => state.auth.roles) || [];

  //TODO: Update implementation of hub, now only have to dispatch a new notification instead of add items to the list
  const {getHubMessage, connection} = useHubConnection(endpoints.signalR.task);

  const {
    appliedFilters,
    currentPage,
    handleRemoveListItem,
    indicators,
    list,
    loadPage,
    onFilter,
    onRefresh,
    onSort,
    pageCount,
    setCurrentPage,
  } = useTaskList();

  // States
  const [community, setCommunity] = useState<number | null>(null);
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [confirmIcon, setConfirmIcon] = useState<EConfirmationModalIcons>(
    EConfirmationModalIcons.Check,
  );
  const [confirmMessage, setConfirmMessage] = useState('');
  const [currentCommunityName, setCurrentCommunityName] = useState<string>('');
  const [currentSort, setCurrentSort] = useState<ICurrentSort>({
    accessor: '',
    direction: 'ascending',
  });
  const [currentTaskId, setcurrentTaskId] = useState<number>(0);
  const [currentTaskTypeSelected, setCurrentTaskTypeSelected] =
    useState<TaskManagerTaskType | null>(null);
  const [data, setData] = useState<ITaskData[] | []>([]);
  const [editsIsOpen, setEditsIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState<ApiAd | null>(null);
  const [selectedBusiness, setSelectedBusiness] =
    useState<IBusinessData | null>(null);
  const [selectedContactUs, setSelectedContactUs] =
    useState<IApiContactUs | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<ApiDeal | null>(null);
  const [selectedTask, setSelectedTask] = useState<IUserToVerify | null>(null);
  const [vendor, setVendor] = useState<IBusinessData | null>(null);
  const [requestEditTitleModal, setRequestEditTitleModal] = useState('');
  const [requestEditTitleContent, setRequestEditTitleContent] = useState('');
  const [requestEditDescriptionContent, setRequestEditDescriptionContent] =
    useState('');

  // Effects
  useEffect(() => {
    buildListData();
  }, [list]);

  useEffect(() => {
    getHubMessage(
      methodNames.newTaskMethodName,
      () => {
        dispatch(
          showPopup(t('taskManagement:newTaskAdded'), 60000, 'info', {
            text: t('taskManagement:view'),
            action: onRefresh,
          }),
        );
      },
      error => console.log(error),
    );
  }, [connection]);

  useEffect(() => {
    if (tasks.isHubAction) {
      if (list.length) {
        showTaskDetail();
      }
      dispatch(isHubConnectionAction(false));
    }
  }, [list]);

  // Functions - Handlers
  const buildListData = () => {
    const x: any = [];

    list.forEach(task => {
      let type = '';
      let getConfirmationData: any = '';

      switch (task.taskType) {
        case TaskManagerTaskType.UserConfirmationRequest:
          type = t('taskManagement:taskTypeAccountVerification');
          getConfirmationData = onAccountConfirmationRequired;
          break;

        case TaskManagerTaskType.DealConfirmationRequest:
          type = t('taskManagement:taskTypeDealApproval');
          getConfirmationData = onDealConfirmationRequired;
          break;

        case TaskManagerTaskType.AdConfirmationRequest:
          type = t('taskManagement:taskTypeAdApproval');
          getConfirmationData = onAdConfirmationRequired;
          break;

        case TaskManagerTaskType.BusinessUpdateRequest:
          type = t('taskManagement:taskTypeBusinessUpdate');
          getConfirmationData = onBusinessConfirmationRequired;
          break;

        case TaskManagerTaskType.ContactUsRequest:
          type = t('taskManagement:taskTypeContactUs');
          getConfirmationData = onContactUsRequest;
          break;

        default:
          break;
      }

      x.push({
        name: task.fullname,
        taskType: type,
        dateRequested: formatDate(task.dateRequested),
        status: (
          <div className="tag-container">
            <Tag
              text={capitalizeFirstLetter(task.status)}
              color={
                task.status === TaskManagerTaskStatus[1]
                  ? EGeneralColors.colorOrangeStatusTask
                  : EGeneralColors.colorRedStatusTask
              }
              backgroundColor={
                task.status === TaskManagerTaskStatus[1]
                  ? EGeneralColors.colorPinkSoftStatusTask
                  : EGeneralColors.colorPinkStatusTask
              }
            />
          </div>
        ),
        actions: (
          <Actions
            ad={task.ad || null}
            business={task.business || null}
            contactUs={task.contactUs || null}
            deal={task.deal || null}
            handleConfirmationData={getConfirmationData}
            handleOpenDetails={handleOpenDetails}
            setCurrentTaskTypeSelected={setCurrentTaskTypeSelected}
            showQuickActions={true}
            taskId={task.id}
            taskType={task.taskType}
            vendorId={task.vendorId && task.vendorId}
          />
        ),
      });
    });
    setData(x);
  };

  const handleOpenDetails = async (
    id: number,
    type: TaskManagerTaskType,
    ad: ApiAd | null,
    deal: ApiDeal | null,
    business: IBusinessData | null,
    contactUs: IApiContactUs | null,
  ) => {
    setcurrentTaskId(id);
    setCurrentTaskTypeSelected(type);
    setModalIsOpen(true);

    if (type === TaskManagerTaskType.UserConfirmationRequest) {
      setSelectedAd(null);
      setSelectedBusiness(null);
      setSelectedContactUs(null);
      setSelectedDeal(null);

      try {
        const response = await getUserToVerify(id);
        if (response && response.data) {
          const responseData = response.data;
          const currentLocation = responseData.business.locations;

          setSelectedTask(responseData);
          setCommunity(responseData.communityId);
          dispatch(
            setCurrentLocation([
              {
                location: {
                  cityName:
                    responseData.city || currentLocation[0].location.cityName,
                  country: currentLocation[0].location.country,
                  state:
                    responseData.state || currentLocation[0].location.state,
                  streetAddress:
                    responseData.address ||
                    currentLocation[0].location.streetAddress,
                },
              },
            ]),
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (ad && type === TaskManagerTaskType.AdConfirmationRequest) {
        setSelectedAd(ad);
        setRequestEditTitleModal(t('tabsModals:titles:titleRequestEdits'));
        setRequestEditTitleContent(ad.title);
        setRequestEditDescriptionContent(
          t('taskManagement:requestEditsModalDescriptionAd', {
            typeTask: ETypeTaskText.Ad,
          }),
        );
      } else if (deal && type === TaskManagerTaskType.DealConfirmationRequest) {
        setSelectedDeal(deal);
        setRequestEditTitleModal(t('tabsModals:titles:titleRequestEdits'));
        setRequestEditTitleContent(deal.title);
        setRequestEditDescriptionContent(
          t('taskManagement:requestEditsModalDescriptionAd', {
            typeTask: ETypeTaskText.Deal,
          }),
        );
        const getCommunityName = user?.communities?.find(
          e => e.id === deal.communityId,
        );
        setCurrentCommunityName(
          getCommunityName?.name || t('global:manaGlobal'),
        );
      } else if (type === TaskManagerTaskType.BusinessUpdateRequest) {
        setSelectedBusiness(business);
      } else if (type === TaskManagerTaskType.ContactUsRequest) {
        setSelectedContactUs(contactUs);
        setRequestEditTitleModal(t('tabsModals:titles:titleRespond'));
        setRequestEditTitleContent(t('tabsModals:titles:titleContactUs'));
        setRequestEditDescriptionContent(
          t('taskManagement:requestEditsModalDescriptionContactUs', {
            adminEmail: user.email,
            userEmail: contactUs?.user.email,
          }),
        );
      }

      if (type !== TaskManagerTaskType.ContactUsRequest) {
        try {
          const idRequest = business?.id || id;
          const response = await getBusinessById(idRequest);
          if (response && response.data) {
            dispatch(setSelectedVendorTask(response.data));
            setVendor(response.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const showTaskDetail = () => {
    const {ad, business, contactUs, deal, taskId, taskType, vendorId} = list[0];
    let id = null;

    if (
      taskType === TaskManagerTaskType.BusinessUpdateRequest ||
      taskType === TaskManagerTaskType.CommentReportRequest ||
      taskType === TaskManagerTaskType.ContactUsRequest ||
      taskType === TaskManagerTaskType.PostReportRequest ||
      taskType === TaskManagerTaskType.UserConfirmationRequest
    ) {
      id = taskId;
    } else {
      id = vendorId || -1;
    }

    return handleOpenDetails(
      id,
      taskType,
      ad || null,
      deal || null,
      business || null,
      contactUs || null,
    );
  };

  //Account confirmation
  const onAccountConfirmationRequired = (
    status: UserConfirmationRequestState,
    task?: IUserToVerify | null,
  ) => {
    setModalIsOpen(false);
    setConfirmationIsOpen(true);

    const data: IChangeUserConfirmationStateRequest = {
      userConfirmationRequestId: task ? task.id : selectedTask?.id || 0,
      userConfirmationRequestState: status,
      userBusinessSponsored: false, //TODO: Hardcoded, improve it when the user story will be updated
      communityId: community,
    };

    let newMessage = t('confirmationTaskApproveDetail');
    setConfirmIcon(EConfirmationModalIcons.Check);

    if (status === UserConfirmationRequestState.Declined) {
      newMessage = t('confirmationTaskDeclineDetail');
      setConfirmIcon(EConfirmationModalIcons.Cross);
    }

    setConfirmMessage(newMessage);
    setConfirmationData(data);
  };

  // Ad confirmation
  const onAdConfirmationRequired = (
    status: TaskManagerTaskState,
    ad?: ApiAd | null,
  ) => {
    setModalIsOpen(false);
    setConfirmationIsOpen(true);

    const data: IUpdateAdTaskRequest = {
      state: status,
      adId: ad ? ad.id : selectedAd?.id || 0,
    };

    let newMessage = t('adConfirmationMessage');
    setConfirmIcon(EConfirmationModalIcons.Check);

    if (status === TaskManagerTaskState.Declined) {
      newMessage = t('adConfirmationRejectMessage');
      setConfirmIcon(EConfirmationModalIcons.Cross);
    }

    setConfirmMessage(newMessage);
    setConfirmationData(data);
  };

  // Deal confirmation
  const onDealConfirmationRequired = (
    status: TaskManagerTaskState,
    deal?: ApiDeal | null,
  ) => {
    setModalIsOpen(false);
    setConfirmationIsOpen(true);

    const data: IUpdateDealTaskRequest = {
      state: status,
      dealId: deal ? deal.id : selectedDeal?.id || 0,
    };

    let newMessage = t('dealConfirmationMessage');
    setConfirmIcon(EConfirmationModalIcons.Check);

    if (status === TaskManagerTaskState.Declined) {
      newMessage = t('dealConfirmationRejectMessage');
      setConfirmIcon(EConfirmationModalIcons.Cross);
    }

    setConfirmMessage(newMessage);
    setConfirmationData(data);
  };

  // Business Update
  const onBusinessConfirmationRequired = (
    status: UserConfirmationRequestState,
    taskId?: number | null,
  ) => {
    setModalIsOpen(false);
    setConfirmationIsOpen(true);

    const data: IUpdateBusinessStateRequest = {
      businessUpdateRequestId: taskId || currentTaskId,
      businessUpdateRequestState: status,
    };

    let newMessage = t('taskManagement:confirmationBusinessApproveDetail');
    setConfirmIcon(EConfirmationModalIcons.Check);

    if (status === UserConfirmationRequestState.Declined) {
      newMessage = t('taskManagement:confirmationBusinessDeclineDetail');
      setConfirmIcon(EConfirmationModalIcons.Cross);
    }

    setConfirmMessage(newMessage);
    setConfirmationData(data);
  };

  // Contact Us
  const onContactUsRequest = (
    status: TaskManagerTaskState,
    taskId?: number,
  ) => {
    setModalIsOpen(false);
    setConfirmationIsOpen(true);

    const data: IContactUsStateRequest = {
      contactUsRequestId: taskId || currentTaskId,
      contactUsResponse: '',
      contactUsSubject: '',
      resolveRequest: true,
      state: status,
    };

    let newMessage = t('taskManagement:confirmationTaskContactUsResolved');
    setConfirmIcon(EConfirmationModalIcons.Check);

    if (status === TaskManagerTaskState.Declined) {
      newMessage = t('taskManagement:confirmationTaskContactUsRejected');
      setConfirmIcon(EConfirmationModalIcons.Cross);
    }

    setConfirmMessage(newMessage);
    setConfirmationData(data);
  };

  // Send Data
  const handleConfirmTask = async (data: any) => {
    try {
      if (
        currentTaskTypeSelected === TaskManagerTaskType.AdConfirmationRequest
      ) {
        const dataRequest: IUpdateAdTaskRequest = data;
        await updateAdTaskStatus(dataRequest);

        if (dataRequest.state === TaskManagerTaskState.Approved) {
          dispatch(showPopup(t('adConfirmedMessage'), 3000, 'success'));
        }

        if (dataRequest.state === TaskManagerTaskState.UpdateRequested) {
          dispatch(showPopup(t('adRequestEditsMessage'), 3000, 'success'));
        }

        if (dataRequest.state === TaskManagerTaskState.Declined) {
          dispatch(showPopup(t('adRejectMessage'), 3000, 'error'));
        }
        handleRemoveListItem(dataRequest?.adId || 0);
      } else if (
        currentTaskTypeSelected === TaskManagerTaskType.DealConfirmationRequest
      ) {
        const dataRequest: IUpdateDealTaskRequest = data;
        await updateDealTaskStatus(dataRequest);

        if (dataRequest.state === TaskManagerTaskState.Approved) {
          dispatch(showPopup(t('dealConfirmedMessage'), 3000, 'success'));
        }

        if (dataRequest.state === TaskManagerTaskState.UpdateRequested) {
          dispatch(showPopup(t('dealRequestEditsMessage'), 3000, 'success'));
        }

        if (dataRequest.state === TaskManagerTaskState.Declined) {
          dispatch(showPopup(t('dealRejectMessage'), 3000, 'error'));
        }
        handleRemoveListItem(dataRequest?.dealId || 0);
      } else if (
        currentTaskTypeSelected === TaskManagerTaskType.BusinessUpdateRequest
      ) {
        const dataRequest: IUpdateBusinessStateRequest = data;
        await updateBusinessStatus(dataRequest);

        if (
          dataRequest.businessUpdateRequestState ===
          UserConfirmationRequestState.Approved
        ) {
          dispatch(
            showPopup(t('accountSuccessfullyVerified'), 3000, 'success'),
          );
        }

        if (
          dataRequest.businessUpdateRequestState ===
          UserConfirmationRequestState.Declined
        ) {
          dispatch(showPopup(t('accountVerificationRejected'), 3000, 'error'));
        }
        handleRemoveListItem(dataRequest?.businessUpdateRequestId || 0);
      } else if (
        currentTaskTypeSelected === TaskManagerTaskType.ContactUsRequest
      ) {
        const {state, ...dataRequest}: IContactUsStateRequest = data;
        await updateContactUsTaskStatus(dataRequest);

        if (state === TaskManagerTaskState.Approved) {
          dispatch(
            showPopup(t('taskContactUsResolvedMessage'), 3000, 'success'),
          );
        }

        if (state === TaskManagerTaskState.UpdateRequested) {
          dispatch(
            showPopup(t('taskContactUsRespondMessage'), 3000, 'success'),
          );
        }

        if (state === TaskManagerTaskState.Declined) {
          dispatch(showPopup(t('taskContactUsRejectedMessage'), 3000, 'error'));
        }
        handleRemoveListItem(dataRequest.contactUsRequestId || 0);
      } else {
        const dataRequest: IChangeUserConfirmationStateRequest = data;
        await updateTaskStatus(dataRequest);

        if (
          dataRequest.userConfirmationRequestState ===
          UserConfirmationRequestState.Approved
        ) {
          dispatch(
            showPopup(t('accountSuccessfullyVerified'), 3000, 'success'),
          );
        }

        if (
          dataRequest.userConfirmationRequestState ===
          UserConfirmationRequestState.Declined
        ) {
          dispatch(showPopup(t('accountVerificationRejected'), 3000, 'error'));
        }
        handleRemoveListItem(selectedTask?.id || 0);
      }
      setConfirmationIsOpen(false);
    } catch (error) {
      console.log(error);
      setConfirmationIsOpen(false);
      dispatch(showPopup(t('global:unexpectedError'), 3000, 'warning'));
    }
  };

  const handleOnAscendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'ascending'});
    switch (accessor) {
      case 'taskType':
        onSort(PendingTaskOrderBy.TaskTypeAscending);
        break;
      case 'dateRequested':
        onSort(PendingTaskOrderBy.DateAscending);
        break;
      case 'status':
        onSort(PendingTaskOrderBy.StatusAscending);
        break;

      default:
        break;
    }
  };

  const handleOnDescendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'descending'});
    switch (accessor) {
      case 'taskType':
        onSort(PendingTaskOrderBy.TaskTypeDescending);
        break;
      case 'dateRequested':
        onSort(PendingTaskOrderBy.DateDescending);
        break;
      case 'status':
        onSort(PendingTaskOrderBy.StatusDescending);
        break;

      default:
        break;
    }
  };

  return (
    <div
      data-testid={'task-manager-page'}
      className="task-manager-page"
      id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        {!userRoles.includes(ERoles.Verificator) && (
          <HeaderContent
            onFilter={onFilter}
            appliedFilters={appliedFilters}
            showFilters
            title={t('global:myTasks')}
            titleFilter={t('global:filterTasks')}
            typeReport={TaskReportType.Task}
          />
        )}

        {appliedFilters && Object.keys(appliedFilters).length > 0 && (
          <AppliedFilters onFilter={onFilter} appliedFilters={appliedFilters} />
        )}

        {!userRoles.includes(ERoles.Verificator) && (
          <Indicators onFilter={onFilter} indicators={indicators} />
        )}

        <Table
          allowedSortBy={allowedSortBy}
          columns={taskTableColumns}
          currentSort={currentSort}
          data={data}
          handleOnAscendingSort={handleOnAscendingSort}
          handleOnDescendingSort={handleOnDescendingSort}
          noResults={<NoResults />}
        />

        {data && data.length > 0 && (
          <Pagination
            currentPage={currentPage}
            loadPage={loadPage}
            pageCount={pageCount}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>

      {/* Deal */}
      {selectedDeal &&
        vendor &&
        currentTaskTypeSelected ===
          TaskManagerTaskType.DealConfirmationRequest && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <Approval
              data={buildDealData(
                vendor,
                currentCommunityName,
                selectedDeal,
                t,
              )}
              deal={selectedDeal}
              closeModal={() => setModalIsOpen(false)}
              onConfirmationRequired={onDealConfirmationRequired}
              taskType={currentTaskTypeSelected}
              operations={[
                {
                  type: ApprovalOperation.rejectDeal,
                  color: EColorsOperationBtns.RedOutlined,
                  label: t('rejectDeal'),
                },
                {
                  type: ApprovalOperation.requestEdits,
                  color: EColorsOperationBtns.BlueOutlined,
                  label: t('requestEdits'),
                },
                {
                  type: ApprovalOperation.approveDeal,
                  color: EColorsOperationBtns.Blue,
                  label: t('approveDeal'),
                },
              ]}
              onRequestEdits={() => {
                setModalIsOpen(false);
                setEditsIsOpen(true);
              }}
            />
          </Modal>
        )}

      {/* Ad */}
      {selectedAd &&
        vendor &&
        currentTaskTypeSelected ===
          TaskManagerTaskType.AdConfirmationRequest && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <Approval
              ad={selectedAd}
              data={buildAdData(selectedAd, vendor, t)}
              closeModal={() => setModalIsOpen(false)}
              onConfirmationRequired={onAdConfirmationRequired}
              taskType={currentTaskTypeSelected}
              operations={[
                {
                  type: ApprovalOperation.rejectAd,
                  color: EColorsOperationBtns.RedOutlined,
                  label: t('rejectAd'),
                },
                {
                  type: ApprovalOperation.requestEdits,
                  color: EColorsOperationBtns.BlueOutlined,
                  label: t('requestEdits'),
                },
                {
                  type: ApprovalOperation.approveAd,
                  color: EColorsOperationBtns.Blue,
                  label: t('approveAd'),
                },
              ]}
              onRequestEdits={() => {
                setModalIsOpen(false);
                setEditsIsOpen(true);
              }}
            />
          </Modal>
        )}

      {/* Account */}
      {selectedTask &&
        currentTaskTypeSelected ===
          TaskManagerTaskType.UserConfirmationRequest && (
          <Modal
            isOpen={selectedTask !== null && modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <ProfileVerification
              data={selectedTask}
              closeModal={() => setModalIsOpen(false)}
              onConfirmationRequired={onAccountConfirmationRequired}
              setCommunity={setCommunity}
            />
          </Modal>
        )}

      {/* Business */}
      {selectedBusiness &&
        currentTaskTypeSelected ===
          TaskManagerTaskType.BusinessUpdateRequest && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <ProfileEditAuthorization
              data={selectedBusiness}
              closeModal={() => setModalIsOpen(false)}
              onConfirmationRequired={onBusinessConfirmationRequired}
            />
          </Modal>
        )}

      {/*Contact Us */}
      {selectedContactUs &&
        currentTaskTypeSelected === TaskManagerTaskType.ContactUsRequest && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <Approval
              data={buildContactUsData(selectedContactUs, t)}
              closeModal={() => setModalIsOpen(false)}
              onConfirmationRequired={onContactUsRequest}
              taskType={currentTaskTypeSelected}
              operations={[
                {
                  type: ApprovalOperation.approveAd,
                  color: EColorsOperationBtns.BlueOutlined,
                  label: t('tabsModals:buttons:btnMarkResolved'),
                },
                {
                  type: ApprovalOperation.requestEdits,
                  color: EColorsOperationBtns.Blue,
                  label: t('tabsModals:buttons:btnRespond'),
                },
              ]}
              onRequestEdits={() => {
                setModalIsOpen(false);
                setEditsIsOpen(true);
                setConfirmMessage(
                  t('taskManagement:confirmationTaskContactUsRespond'),
                );
                setConfirmIcon(EConfirmationModalIcons.Check);
              }}
            />
          </Modal>
        )}

      {/* Request Edit */}
      {editsIsOpen && (
        <Modal
          isOpen={editsIsOpen}
          onRequestClose={() => setEditsIsOpen(false)}
          style={editModalStyles}>
          <RequestEdits
            adData={selectedAd}
            contactUsData={selectedContactUs}
            contentDescription={requestEditDescriptionContent}
            contentTitle={requestEditTitleContent}
            dealData={selectedDeal}
            modalTitle={requestEditTitleModal}
            onCloseModal={() => {
              setEditsIsOpen(false);
              setModalIsOpen(true);
            }}
            onGoBack={() => {
              setEditsIsOpen(false);
              setModalIsOpen(true);
            }}
            onSend={(data: any) => {
              setConfirmationData(data);
              setConfirmationIsOpen(true);
              setEditsIsOpen(false);
            }}
            taskId={currentTaskId}
            taskType={currentTaskTypeSelected}
          />
        </Modal>
      )}

      {/* Confirmation Modal (Send Data) */}
      {confirmationIsOpen && (
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={() => setConfirmationIsOpen(false)}
          style={confirmModalStyles}>
          <ConfirmationModal
            question={t('confirmationTaskQuestion')}
            detail={confirmMessage}
            icon={confirmIcon}
            onConfirm={() => handleConfirmTask(confirmationData)}
            onCancel={() => {
              setConfirmationIsOpen(false);
              setModalIsOpen(true);
            }}
            onClose={() => setConfirmationIsOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default TaskManagerPage;
