import React, {FC, useState} from 'react';
import {ReactComponent as BlackElipsis} from '../../assets/icons/black-ellipses.svg';
import Dropdown from '../Dropdown';
import DropDownItem from '../DropDownItem';
import {useTranslation} from 'react-i18next';
import {EDropDownItemsTextColor} from '../../models/enums/generals';

interface Props {
  actionsList: {label: string; action: () => void}[];
}
const Actions: FC<Props> = ({actionsList}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageX, setPageX] = React.useState<number>(0);
  const [pageY, setPageY] = React.useState<number>(0);

  const {t} = useTranslation();

  const handleElipsisClick = (event: any) => {
    setIsOpen(!isOpen);
    setPageX(event.clientX);
    setPageY(event.clientY);
  };

  return (
    <div className="elipsis-icon-action-list" onClick={handleElipsisClick}>
      <BlackElipsis />
      <Dropdown
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(true)}
        options={actionsList.map((a, index) => (
          <DropDownItem
            text={t(a.label)}
            onClick={() => {
              a.action();
              setIsOpen(false);
            }}
            colorItem={
              index === 2
                ? EDropDownItemsTextColor.Red
                : EDropDownItemsTextColor.Black
            }
            key={index}
          />
        ))}
        pageX={pageX}
        pageY={pageY}
      />
    </div>
  );
};

export default Actions;
