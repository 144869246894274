import {IMembershipsBodyRequest} from '../../models/api/memberships';
import {store} from '../../redux';
import {parsePublishPostToFormData} from '../../utils/parseToFormData';
import endpoints from './endpoints';
import {genericPost} from './genericAPI';

export const getMembershipsList = async (data: IMembershipsBodyRequest) => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.user.search, token, data);
};

export const updateUserMembership = async (
  userId: string,
  membership: number,
) => {
  const token = store.getState().auth.token;
  const fdata = parsePublishPostToFormData({userId, membership});

  return genericPost(endpoints.user.updateMembership, token, fdata);
};
