import {CategoryI} from '../../models/api/category';
import {IEvent, IEventListRequest} from '../../models/api/event';
import {EventOrderBy} from '../../models/enums/eventOrderBy';
import {getCategories} from '../../services/api/categoryApi';
import {getEventsList} from '../../services/api/eventApi';
import {updateEvent} from '../../services/api/eventApi';
import {RootState} from '../reducers';

import {
  CLEAR_EVENT_LIST_PARAMS,
  CLEAR_EVENT_STATE,
  SET_EVENT_LIST_SORTING,
  SET_EVENT_LIST_IS_LOADING,
  SET_EVENT_LIST,
  EventActionTypes,
  SET_EVENT_CURRENT_PAGE,
  SET_EVENT_COMMUNITY,
  SET_CATEGORIES,
  SET_EVENT_CATEGORY,
} from '../types/eventListTypes';

export const setEventList = (payload: IEvent[]): EventActionTypes => ({
  type: SET_EVENT_LIST,
  payload,
});

export const clearEventListParams = (): EventActionTypes => ({
  type: CLEAR_EVENT_LIST_PARAMS,
});

export const clearEventState = (): EventActionTypes => ({
  type: CLEAR_EVENT_STATE,
});

export const setEventListSorting = (
  payload: EventOrderBy,
): EventActionTypes => ({
  type: SET_EVENT_LIST_SORTING,
  payload,
});

export const setEventListIsLoading = (payload: boolean): EventActionTypes => ({
  type: SET_EVENT_LIST_IS_LOADING,
  payload,
});

export const setEventCurrentPage = (payload: number): EventActionTypes => ({
  type: SET_EVENT_CURRENT_PAGE,
  payload,
});

export const setEventCommunity = (payload: IEvent): EventActionTypes => ({
  type: SET_EVENT_COMMUNITY,
  payload,
});

export const setCategoriesState = (payload: CategoryI[]): EventActionTypes => ({
  type: SET_CATEGORIES,
  payload,
});

export const setEventCategory = (payload: IEvent): EventActionTypes => ({
  type: SET_EVENT_CATEGORY,
  payload,
});

export const getAndSaveEvents =
  (data: IEventListRequest) => async (dispatch: any, getState: any) => {
    try {
      const {
        event: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setEventListIsLoading(true));

      const response = await getEventsList(data);

      dispatch(setEventList(response.data));
      dispatch(setEventListIsLoading(false));
    } catch (err) {
      dispatch(setEventListIsLoading(false));

      console.log('Error: Fail on get Events: ', err);
    }
  };

export const updateEventCommunity =
  (categoryId: number | null, eventId: number, communityId: number | null) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const {
        event: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }
      dispatch(setEventListIsLoading(true));
      const {categories} = getState().event;
      const category = categories.find(c => c.id === categoryId);
      const subCategory = category?.subCategories
        ? category.subCategories[0].id
        : undefined;

      const data = {
        eventId,
        communityId,
        subCategories: subCategory ? [{id: subCategory}] : undefined,
      };

      const response = await updateEvent(data);

      dispatch(setEventCommunity(response.data));
      dispatch(setEventListIsLoading(false));
    } catch (err) {
      dispatch(setEventListIsLoading(false));

      console.log(`Error: Fail on update event ${eventId}: `, err);
    }
  };

export const updateEventCategory =
  (categoryId: number | null, eventId: number, communityId: number | null) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const {categories} = getState().event;
      const category = categories.find(c => c.id === categoryId);
      const subCategory = category?.subCategories
        ? category.subCategories[0].id
        : undefined;

      const data = {
        eventId,
        communityId,
        subCategories: subCategory ? [{id: subCategory}] : undefined,
      };

      dispatch(setEventListIsLoading(true));

      const response = await updateEvent(data);
      dispatch(setEventListIsLoading(false));
      dispatch(setEventCategory(response.data));
    } catch (err) {
      dispatch(setEventListIsLoading(false));

      console.log(`Error: Fail on update event ${eventId}: `, err);
    }
  };

export const setCategories = () => async (dispatch: any) => {
  try {
    const categories = await getCategories();
    dispatch(setCategoriesState(categories.data));
  } catch (e) {
    console.log(`Error: Fail on load categories: `, e);
  }
};
