export enum TaskManagerTaskType {
  NoneSelected = 0,
  UserConfirmationRequest,
  DealConfirmationRequest,
  AdConfirmationRequest,
  CommentReportRequest,
  PostReportRequest,
  BusinessUpdateRequest = 7,
  ContactUsRequest,
}

export enum UserReportType {
  Nudity = 1,
  Violence,
  Harassment,
  SuicideSelfInjury,
  FalseInformation,
  Spam,
  UnauthorizedSales,
  Terrorism,
  Discriminatory,
  Other,
}

export enum TaskFilterOptions {
  AdApprovals = 'Ad approvals',
  AccountVerifications = 'Account verifications',
  DealApprovals = 'Deal approvals',
  ContactUsVerifications = 'Contact us',
  UrgentTasks = 'Urgent Tasks',
  UnresolvedTasks = 'Unresolved Tasks',
  CommentReports = 'Message Report',
  PostReports = 'Post Report',
  ResolvedReports = 'Resolved Reports',
}

export enum TaskColorCard {
  Blue = '#5D9FEA',
  Green = '#36B37E',
  Orange = '#D05300',
  Purple = '#C25791',
  Red = '#DE4842',
  Yellow = '#E69C1E',
}

export enum TaskFilterType {
  Type = 'TYPE',
  State = 'STATE',
}

export enum TaskReportType {
  Event = 'EVENT',
  News = 'MANA NEWS',
  Report = 'REPORT',
  Task = 'TASK',
  User = 'USER',
  Communities = 'COMMUNITIES',
  Cities = 'CITIES',
  Memberships = 'MEMBERSHIPS',
}
