import React from 'react';
import {EGeneralColors} from '../../models/enums/colors';
import {ISelectOptions} from '../../models/interfaces/forms';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as Tick} from '../../assets/icons/tick.svg';
import CSS from 'csstype';
import Select, {components} from 'react-select';
import './styles.scss';

// TODO: Fix this type using StyleConfig
const customStyles: any = {
  menuList: (base: any) => ({
    ...base,
    maxHeight: 220,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  control: (base: any, state: any) => ({
    ...base,
    '&:hover': {borderColor: 'none'},
    backgroundColor: state.isFocused
      ? EGeneralColors.colorWhiteCultured
      : EGeneralColors.colorWhite,
    border: 'border: 1px solid',
    borderColor: state.isFocused
      ? EGeneralColors.colorDarkBlue
      : EGeneralColors.colorGraySilver,
    borderRadius: 0,
    boxShadow: 'none',
    height: 36,
    width: '100%',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  menu: (base: any) => ({
    ...base,
    background: EGeneralColors.colorWhite,
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
    justifyContent: 'center',
    overflowY: 'auto',
    width: '100%',
  }),
  option: () => ({border: '1px solid red'}),
};

interface IProps {
  customStylesComponent?: {generalContainer: CSS.Properties};
  defaultOption?: number | string;
  isDisabled?: boolean;
  isEdit?: boolean;
  items: ISelectOptions[] | [];
  label: string;
  nameSelected?: string;
  onChange: (newValue: any) => void;
  placeHolder: string;
}

const SelectField = ({
  customStylesComponent,
  defaultOption,
  isDisabled = false,
  isEdit,
  items,
  label,
  nameSelected,
  onChange,
  placeHolder,
}: IProps) => {
  const Placeholder = (props: any) => {
    return <components.Placeholder {...props} />;
  };

  const CustomIndicator = () => {
    return (
      <ChevronDown
        className=""
        width={11}
        height={11}
        fill={EGeneralColors.colorGray}
      />
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomIndicator />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    const {
      data: {label},
      isSelected,
      innerProps,
    } = props;
    return (
      <div className="option-item-container">
        <span
          className={`option-item ${isSelected ? 'selected' : ''}`}
          onClick={innerProps.onClick}>
          {label}
          {isSelected && (
            <Tick
              className="selected-icon"
              width={11}
              fill={EGeneralColors.colorDarkBlue}
            />
          )}
        </span>
      </div>
    );
  };

  return (
    <div
      className="select-component"
      style={customStylesComponent && customStylesComponent.generalContainer}>
      <span className={'select-label'}>{label}</span>
      {defaultOption === '' || !isEdit ? (
        <Select
          components={{Placeholder, DropdownIndicator, Option}}
          inputValue={nameSelected}
          isDisabled={isDisabled}
          menuPlacement="auto"
          onChange={onChange}
          options={items}
          placeholder={placeHolder}
          styles={customStyles}
        />
      ) : (
        <Select
          components={{Placeholder, DropdownIndicator, Option}}
          inputValue={nameSelected}
          isDisabled={isDisabled}
          menuPlacement="auto"
          onChange={onChange}
          options={items}
          placeholder={placeHolder}
          styles={customStyles}
          value={items.find(option => option.value === defaultOption)}
        />
      )}
    </div>
  );
};

export default SelectField;
