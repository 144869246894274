import {useDispatch, useSelector} from 'react-redux';
import {CreateCityI, CityI} from '../models/api/city';
import {
  createCityService,
  updateCityService,
  deleteCityService,
  getCitiesListService,
} from '../services/api/citiesApi';
import {showPopup} from '../redux/actions/uiActions';
import {useTranslation} from 'react-i18next';
import {CreateCommunityI} from '../models/api/comunity';
import {
  createCommunityService,
  deleteCommunityService,
  getCommunitiesListService,
  updateCommunityService,
} from '../services/api/communitiesApi';
import {
  setCityList,
  setCommunityList,
} from '../redux/actions/comunityCityActions';
import {useEffect} from 'react';
import {RootState} from '../redux';

const useCommunityCity = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {cities, communities} = useSelector(
    (state: RootState) => state.communityCity,
  );

  useEffect(() => {
    listCities();
    listCommunities();
  }, []);

  const getCityName = (cityId: number) => {
    const city = cities.find(c => c.id === cityId);
    return city ? city.name : '';
  };

  const createCity = async (city: CreateCityI) => {
    try {
      await createCityService(city);
      listCities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const updateCity = async (city: CityI) => {
    try {
      await updateCityService(city);
      listCities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const deleteCity = async (cityId: number) => {
    try {
      await deleteCityService(cityId);
      listCities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const listCities = async () => {
    try {
      const {data} = await getCitiesListService();
      dispatch(setCityList(data));
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const createCommunity = async (community: CreateCommunityI) => {
    try {
      await createCommunityService(community);
      listCommunities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const updateCommunity = async (community: CreateCommunityI) => {
    try {
      await updateCommunityService(community);
      listCommunities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const deleteCommunity = async (communityId: number) => {
    try {
      await deleteCommunityService(communityId);
      listCommunities();
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  const listCommunities = async () => {
    try {
      const {data} = await getCommunitiesListService();
      dispatch(setCommunityList(data));
    } catch (e) {
      dispatch(showPopup(t('errorsMessage:genericError'), 3000, 'error'));
    }
  };

  return {
    createCity,
    updateCity,
    deleteCity,
    listCities,
    createCommunity,
    updateCommunity,
    deleteCommunity,
    listCommunities,
    cities,
    communities,
    getCityName,
  };
};

export default useCommunityCity;
