import {CityI} from '../../models/api/city';
import {CommunityI} from '../../models/api/comunity';

export const SET_COMMUNITIES_LIST = 'SET_COMMUNITIES_LIST';
export const SET_CITIES_LIST = 'SET_CITIES_LIST';

interface ISetCommunitiesList {
  type: typeof SET_COMMUNITIES_LIST;
  payload: CommunityI[];
}

interface ISetCitiesList {
  type: typeof SET_CITIES_LIST;
  payload: CityI[];
}

export type CommunityCityActions = ISetCommunitiesList | ISetCitiesList;
