import React, {FC} from 'react';
import {TextField} from '../../../../components/TextField';
import {useFormikContext} from 'formik';
import {CityFormI} from '../../../../models/interfaces/cityInterface';
import './styles.scss';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import {useTranslation} from 'react-i18next';

interface Props {
  onClose: () => void;
  onConfirm: (value: CityFormI) => void;
}

const CityForm: FC<Props> = ({onClose, onConfirm}) => {
  const {values, setFieldValue} = useFormikContext<CityFormI>();
  const {t} = useTranslation('global');

  return (
    <div className="city-form">
      <ConfirmationModal
        onCancel={onClose}
        onClose={onClose}
        onConfirm={() => onConfirm(values)}>
        <>
          <div className="input-container">
            <TextField
              value={values.name}
              handleChange={e => setFieldValue('name', e.target.value)}
              type="text"
              id="name"
              placeHolder={t('labelFields.name')}
              label={t('labelFields.name')}
            />
          </div>
          <div className="check-container">
            <p className="text-coming-soon">{t('isComingSoon')}</p>
            <input
              className="check"
              defaultChecked
              type="checkbox"
              onChange={() => {
                setFieldValue('comingSoon', !values.comingSoon);
              }}
            />
          </div>
        </>
      </ConfirmationModal>
    </div>
  );
};
export default CityForm;
