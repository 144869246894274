import React, {useEffect, useState} from 'react';
import {
  clearPostCreateState,
  getFail,
  sendPublishPostData,
  setPostData,
  setSendingRequest,
} from '../../redux/actions/postActions';
import {AppState} from '../../redux';
import {createMultimediaFile} from '../../utils/createMultimediaFile';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {EGeneralColors} from '../../models/enums/colors';
import {ETypeFiles, ETypePost} from '../../models/enums/postNews';
import {ICurrentSort} from '../../models/interfaces/table';
import {IPostData} from '../../models/interfaces/postInterface';
import {IPostSendPublishData} from '../../models/api/post';
import {manaNewsOrderBy} from '../../models/enums/manaNewsOrderBy';
import {NewsAccesors} from '../../models/enums/taskTable';
import {newsTableColumns} from '../../constants/tableColumns';
import {setCurrentPublication} from '../../redux/actions/postActions';
import {showPopup} from '../../redux/actions/uiActions';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {useDispatch, useSelector} from 'react-redux';
import {usePostList} from '../../hooks/usePostList';
import {useTranslation} from 'react-i18next';
import ButtonWithIcon from '../../components/buttonWithIcon';
import CommentsModal from './components/CommentsModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import CreateNews from '../../components/CreateNews';
import HeaderContent from '../../components/HeaderContent';
import LeftMenu from '../../components/LeftMenu';
import LinkButton from '../../components/LinkButton';
import Modal from 'react-modal';
import moment from 'moment';
import NoResults from '../TaskManagerPage/components/NoResults';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import './styles.scss';
import DrawerMenu from '../../components/DrawerMenu';

const NewsPage = () => {
  // Hooks
  const postState = useSelector((state: AppState) => state.post);
  const dispatch = useDispatch();
  const {t} = useTranslation('manaNews');
  const {
    currentPage,
    handleGetList,
    handleRemoveListItem,
    list,
    loadPage,
    onSort,
    pageCount,
    setCurrentPage,
  } = usePostList();

  // Local State
  const [currentSort, setCurrentSort] = useState<ICurrentSort>({
    accessor: '',
    direction: 'ascending',
  });
  const [data, setData] = useState<IPostData[] | []>([]);
  const [idDelete, setidDelete] = useState<null | number>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false);
  const [multimediaFile, setMultimediaFile] = useState<any>();
  const [overridePublisherId, setOverridePublisherId] = useState<string | null>(
    null,
  );
  const [postId, setpostId] = useState<number | null>(null);

  // Data
  const allowedSortBy = [
    NewsAccesors.DatePosted,
    NewsAccesors.Reactions,
    NewsAccesors.Comments,
  ];

  const dateFormat = 'M-D-YYYY';

  // Efects
  useEffect(() => {
    const record: IPostData[] = [];
    list.forEach((item: any) => {
      const {
        reactionsThumbsUp,
        reactionsSmileBean,
        reactionsHeart,
        reactionsAngry,
        reactionsFrown,
        reactionsSurprise,
        publisherFullname,
        createdAt,
        text,
        id,
      } = item;

      const reactions =
        reactionsThumbsUp +
        reactionsSmileBean +
        reactionsHeart +
        reactionsAngry +
        reactionsFrown +
        reactionsSurprise;
      const numberOfComments = item.comments.length;

      record.push({
        publisherFullname,
        text,
        createdAt: moment(createdAt).format(dateFormat),
        reactions,
        numberOfComments: (
          <div className="button-center">
            <LinkButton
              label={`${numberOfComments}`}
              onClick={() => goToComment(item)}
              color={EGeneralColors.colorBlueLink}
            />
          </div>
        ),
        actions: (
          <div className="button-center">
            <ButtonWithIcon
              text={t('global:delete')}
              color="red"
              onClick={() => openModalConfirmation(id)}
            />
          </div>
        ),
      });
    });
    setData(record);
  }, [list]);

  useEffect(() => {
    if (postState.data.postMultimediaUrl) {
      getMultimediaFile(postState.data.postMultimediaUrl);
    }
  }, [postState.data]);

  // Handlers - Functions
  const handleCreateNew = () => {
    setIsCreateModalOpen(true);
  };

  const handleOnAscendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'ascending'});
    switch (accessor) {
      case NewsAccesors.DatePosted:
        onSort(manaNewsOrderBy.CreationDateAscending);
        break;
      case NewsAccesors.Reactions:
        onSort(manaNewsOrderBy.ReactionsAscending);
        break;

      case NewsAccesors.Comments:
        onSort(manaNewsOrderBy.CommentsAscending);
        break;
      default:
        break;
    }
  };

  const handleOnDescendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'descending'});
    switch (accessor) {
      case NewsAccesors.DatePosted:
        onSort(manaNewsOrderBy.CreationDateDescending);
        break;
      case NewsAccesors.Reactions:
        onSort(manaNewsOrderBy.ReactionsDescending);
        break;
      case NewsAccesors.Comments:
        onSort(manaNewsOrderBy.CommentsDescending);
        break;
      default:
        break;
    }
  };

  const confirmation = () => {
    dispatch(showPopup(t('manaNews:notificationMessageDelete'), 3000, 'error'));
    idDelete && handleRemoveListItem(idDelete);
    closeModalConfirmation();
  };

  const openModalConfirmation = (id: number) => {
    setidDelete(id);
    setmodalIsOpen(true);
  };

  const closeModalConfirmation = () => {
    setidDelete(null);
    setmodalIsOpen(false);
  };

  const goToComment = (publication: any) => {
    setpostId(publication.id);
    setOverridePublisherId(publication.publisherId);

    const multimediaFile = publication?.files[0];
    const typeMultimedia =
      multimediaFile && multimediaFile?.fileType.includes('mp4')
        ? ETypeFiles.Video
        : ETypeFiles.Image;

    dispatch(
      setPostData({
        postCaption: publication.text,
        postCaptionUrl: publication.previewLink,
        postDate: moment(publication.createdAt).startOf('day').fromNow(),
        postMultimediaUrl: publication?.files[0]?.url,
        postTypeMultimediaFile: typeMultimedia,
        userFullname: publication.publisherFullname,
        userImageUrl: publication.publisherProfileUrl,
      }),
    );
    dispatch(setCurrentPublication(publication));
  };

  const onCreatePost = () => {
    const sendObjectData: IPostSendPublishData = {
      categoryId: 1,
      communityId: postState.data.communityId,
      overridePublisherId: postState.data.overridePublisherId,
      previewLink: postState.data.postCaptionUrl,
      text: postState.data.postCaption,
      visibility: 1,
    };

    if (postState.data.postMultimediaUrl) {
      dispatch(
        sendPublishPostData({
          ...sendObjectData,
          files: multimediaFile,
          postAsManaNews: 'true',
        }),
      );
    } else {
      dispatch(
        sendPublishPostData({
          ...sendObjectData,
          postAsManaNews: true,
        }),
      );
    }
  };

  const onCancelProcess = () => {
    setIsCreateModalOpen(false);
    resetManaNewsPostCreate();
  };

  const onBack = () => {
    resetManaNewsPostCreate();
  };

  const getMultimediaFile = async (file: any) => {
    try {
      const multimediaFile = await createMultimediaFile(file, 'multimediaFile');
      setMultimediaFile(multimediaFile);
    } catch (e) {
      console.log('error');
      dispatch(
        showPopup(t('errorsMessage:errorProcessingFile'), 3000, 'error'),
      );
    }
  };

  const resetManaNewsPostCreate = () => {
    dispatch(clearPostCreateState());
    dispatch(setSendingRequest(false));
    dispatch(getFail(''));

    handleGetList();
    onSort(manaNewsOrderBy.CreationDateDescending);
  };

  useEffect(() => {
    const record: IPostData[] = [];
    list.forEach((item: any) => {
      const {
        reactionsThumbsUp,
        reactionsSmileBean,
        reactionsHeart,
        reactionsAngry,
        reactionsFrown,
        reactionsSurprise,
        publisherFullname,
        createdAt,
        text,
        id,
      } = item;

      const reactions =
        reactionsThumbsUp +
        reactionsSmileBean +
        reactionsHeart +
        reactionsAngry +
        reactionsFrown +
        reactionsSurprise;
      record.push({
        publisherFullname,
        text,
        createdAt: moment(createdAt).format(dateFormat),
        reactions,
        numberOfComments: (
          <div className="button-center">
            <LinkButton
              label={`${item.numberOfComments}`}
              onClick={() => goToComment(item)}
              color={EGeneralColors.colorBlueLink}
            />
          </div>
        ),
        actions: (
          <div className="button-center">
            <ButtonWithIcon
              text={t('global:delete')}
              color="red"
              onClick={() => openModalConfirmation(id)}
            />
          </div>
        ),
      });
    });
    setData(record);
  }, [list]);

  return (
    <div className="news-page" id="layout">
      <LeftMenu />
      <DrawerMenu />

      <div className="page-content">
        <HeaderContent
          showFilters={false}
          title={t('menu:manaNews')}
          createNew
          onClick={handleCreateNew}
          typeReport={TaskReportType.News}
        />
        <Table
          columns={newsTableColumns}
          handleOnAscendingSort={handleOnAscendingSort}
          handleOnDescendingSort={handleOnDescendingSort}
          currentSort={currentSort}
          allowedSortBy={allowedSortBy}
          data={data}
          noResults={
            <div>
              <NoResults text={t('manaNews:noManaNew')} />
            </div>
          }
        />

        {data && data.length > 0 && (
          <Pagination
            pageCount={pageCount}
            loadPage={loadPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}

        {/* Create New */}
        {isCreateModalOpen && (
          <Modal
            isOpen={isCreateModalOpen}
            onRequestClose={onCancelProcess}
            style={createModalStyles}>
            <CreateNews
              onBack={onBack}
              onCancelProcess={onCancelProcess}
              onCloseModal={onCancelProcess}
              onSendData={onCreatePost}
              title={t('manaNews:modalTitle')}
              typeProcess={ETypePost.Create}
            />
          </Modal>
        )}

        {/* Confirm send */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setmodalIsOpen(false)}
          style={customStyles}>
          <ConfirmationModal
            question={t('manaNews:deletePost')}
            detail={t('manaNews:deleteDescription')}
            icon={EConfirmationModalIcons.Trash}
            onConfirm={() => confirmation()}
            onCancel={() => closeModalConfirmation()}
            onClose={() => closeModalConfirmation()}
          />
        </Modal>

        <CommentsModal
          overridePublisherId={overridePublisherId}
          postId={postId}
        />
      </div>
    </div>
  );
};

export default NewsPage;

const customStyles: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.4)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 10,
  },
  content: {
    border: 0,
    borderRadius: '25px',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxWidth: 880,
    minWidth: 445,
    padding: 0,
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    top: '50%',
  },
};

const createModalStyles: ReactModal.Styles = {
  ...customStyles,
  content: {
    ...customStyles.content,
    maxWidth: 880,
    width: '70%',
  },
};
