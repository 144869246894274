import React, {useEffect, useState} from 'react';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {useTranslation} from 'react-i18next';
import {showPopup} from '../../../../redux/actions/uiActions';
import {useDispatch} from 'react-redux';
import {EGeneralColors} from '../../../../models/enums/colors';
import './styles.scss';
import {CategoryI} from '../../../../models/api/category';
import Modal from 'react-modal';
import {generalModalStyles} from '../../../../constants/styles';
import InputSearch from '../../../../components/InputSearch';
import {useAsyncDebounce} from 'react-table';

interface IProps {
  categories: CategoryI[];
  selectedId?: number | null;
  handleChangeCategory: (categoryId: number) => void;
}

interface FormatedCategoryI {
  id: number;
  translation: string;
}
const CategorySelector = ({
  categories,
  selectedId,
  handleChangeCategory,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryI>();
  const [categoryTranslations, setCategoryTranslations] = useState<
    FormatedCategoryI[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [filtredData, setFiltredData] = useState(categoryTranslations);

  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const userLanguageCode = i18n.resolvedLanguage;

  const communityName =
    selectedCategory && selectedCategory.subCategories
      ? selectedCategory?.subCategories[0].translations.find(
          t => t.language.languageCode === userLanguageCode,
        )?.value
      : '-';

  useEffect(() => {
    const formatedCategories = categories.map(c => {
      const subCategory = c.subCategories?.find(sc => sc.id === selectedId);
      if (selectedId && subCategory) {
        setSelectedCategory(c);
      }
      return {
        id: c.id,
        translation:
          c.translations.find(t => t.language.languageCode === userLanguageCode)
            ?.value ?? '',
      };
    });

    const updatedCategories = [
      {id: 0, translation: 'All'},
      ...formatedCategories,
    ];

    console.log('updatedCategories', updatedCategories);

    setCategoryTranslations(updatedCategories);
    setFiltredData(updatedCategories);
  }, [categories]);
  console.log('filtredData', filtredData);
  console.log('categoryTranslations', categoryTranslations);

  const handleSelectCategory = (categoryId: number) => {
    setIsModalOpen(false);
    setSelectedCategory(categories.find(c => c.id === categoryId));
    handleChangeCategory(categoryId);
    dispatch(
      showPopup(t('events:notificationMessageChanged'), 3000, 'success'),
    );
  };

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleSearch = useAsyncDebounce((value: string) => {
    const newData = categoryTranslations.filter(ct =>
      ct.translation.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    );
    setFiltredData(newData);
  }, 300);

  return (
    <div className="table-buttons">
      <div className="community-wraper">{communityName}</div>
      <span className="community-selector" onClick={handleOnClick}>
        <div className="chevron">
          <ChevronDown fill={EGeneralColors.colorBlueLink} />
        </div>
      </span>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={{
          ...generalModalStyles,
          content: {...generalModalStyles.content, maxWidth: '50%'},
        }}>
        <div className="modal-wraper">
          <InputSearch
            value={searchValue}
            onChange={v => {
              setSearchValue(v.target.value);
              handleSearch(v.target.value);
            }}
            id="1"
            showLabel={false}
          />
          <div className="categories-container">
            {filtredData.map(c => {
              return (
                <p
                  className="dropdown-item"
                  key={c.id}
                  onClick={() => handleSelectCategory(c.id)}>
                  {c.translation}
                </p>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CategorySelector;
