import {CityI} from '../../models/api/city';
import {CommunityI} from '../../models/api/comunity';
import {
  CommunityCityActions,
  SET_CITIES_LIST,
  SET_COMMUNITIES_LIST,
} from '../types/communityCity.types';

interface CommunityCityState {
  communities: CommunityI[];
  cities: CityI[];
}

const initialState: CommunityCityState = {cities: [], communities: []};

const communityCityReducer = (
  state = initialState,
  action: CommunityCityActions,
): CommunityCityState => {
  switch (action.type) {
    case SET_COMMUNITIES_LIST: {
      return {
        ...state,
        communities: action.payload,
      };
    }
    case SET_CITIES_LIST: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    default:
      return state;
  }
};

export default communityCityReducer;
