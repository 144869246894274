import {EventOrderBy} from '../models/enums/eventOrderBy';
import {GENERAL_PER_PAGE} from '../constants/paginator';
import {IEvent} from '../models/api/event';
import {RootState} from '../redux';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  clearEventState,
  getAndSaveEvents,
  setEventCurrentPage,
  setEventListSorting,
  updateEventCategory,
  updateEventCommunity,
} from '../redux/actions/eventActions';

interface IUseEventsList {
  list: IEvent[];
  handleGetList: () => void;
  handleChangeCommunity: (
    communityId: number | null,
    eventId: number,
    categoryId: number | null,
  ) => void;
  pageCount: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  loadPage: (page: number) => void;
  onSort: (newSort: EventOrderBy) => void;
  handleChangeCategory: (
    categoryId: number,
    eventId: number,
    communityId: number | null,
  ) => void;
}

const useEventsList = (): IUseEventsList => {
  const [list, setList] = useState<IEvent[]>([]);

  const {
    results,
    sortBy,
    pageCount,
    currentPage: stateCurrentPage,
  } = useSelector((state: RootState) => state.event);

  const [currentPage, setCurrentPage] = useState(
    stateCurrentPage === 0 ? stateCurrentPage : stateCurrentPage - 1,
  );
  const dispatch = useDispatch();

  const handleGetList = () => {
    dispatch(
      getAndSaveEvents({
        eventListSort: sortBy,
        eventPresenceType: 1,
        eventTicketPrice: 1,
        page: currentPage + 1,
        perPage: GENERAL_PER_PAGE,
        getOnlyPastEvents: false,
      }),
    );
  };

  //Effects
  useEffect(() => {
    dispatch(clearEventState());
    handleGetList();
  }, []);

  useEffect(() => {
    if (currentPage > -1) {
      handleGetList();
    }
  }, [currentPage]);

  useEffect(() => {
    setList(results);
  }, [results]);

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    handleGetList();
  }, [sortBy, currentPage]);

  const onSort = (orderBy: EventOrderBy) => {
    dispatch(setEventListSorting(orderBy));
  };

  const handlePage = (page: number) => {
    dispatch(setEventCurrentPage(page));
  };

  const handleChangeCategory = (
    categoryId: number | null,
    eventId: number,
    communityId: number | null,
  ) => {
    dispatch(updateEventCategory(categoryId, eventId, communityId));
  };

  const handleChangeCommunity = (
    communityId: number | null,
    eventId: number,
    categoryId: number | null,
  ) => {
    dispatch(updateEventCommunity(categoryId, eventId, communityId));
  };

  return {
    list,
    handleGetList,
    onSort,
    currentPage,
    setCurrentPage,
    pageCount,
    loadPage: handlePage,
    handleChangeCommunity,
    handleChangeCategory,
  };
};

export default useEventsList;
