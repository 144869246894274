export enum TiersHeaders {
  TierName = 'Tier Name',
  TierType = 'Tier Type',
  Price = 'Price',
  Duration = 'Duration',
  Impressions = 'Impressions',
  Actions = 'Actions',
}
export enum NewsHeaders {
  PostedBy = 'Posted by',
  Caption = 'Caption',
  DatePosted = 'Date posted',
  Reactions = 'Reactions',
  Shares = 'Shares',
  Comments = 'Comments',
  Deletion = 'Deletion',
}
export enum NewsAccesors {
  PostedBy = 'publisherFullname',
  Caption = 'text',
  DatePosted = 'createdAt',
  Reactions = 'reactions',
  Shares = 'shares',
  Comments = 'numberOfComments',
  Deletion = 'actions',
}

export enum TiersAccesors {
  TierName = 'tierName',
  TierType = 'tierType',
  Price = 'price',
  Duration = 'duration',
  Impressions = 'impressions',
  Actions = 'actions',
}

export enum ReportsHeaders {
  ReportedAccount = 'Reported Account',
  ReportType = 'Report Type',
  Date = 'Date',
  Status = 'Status',
  Actions = 'Actions',
}

export enum ReportsAccesors {
  ReportedAccount = 'reportedAccount',
  ReportType = 'reportType',
  Date = 'date',
  Status = 'status',
  Actions = 'actions',
}

export enum TaskManagerHeaders {
  Name = 'Name',
  TaskType = 'Task type',
  DateRequest = 'Date requested',
  Status = 'Status',
  Actions = 'Actions',
}

export enum TaskManagerAccesors {
  Name = 'name',
  TaskType = 'taskType',
  DateRequest = 'dateRequested',
  Status = 'status',
  Actions = 'actions',
}

export enum EventHeader {
  Name = 'Event Name',
  DateAndTime = 'Date & Time',
  Location = 'Location',
  Community = 'Event Community',
  Category = 'Category',
}

export enum EventAccesors {
  Name = 'name',
  DateAndTime = 'startDate',
  Location = 'location',
  Community = 'communityId',
  Category = 'category',
}

export enum UsersAdminHeader {
  Name = 'Name',
  Role = 'Role',
  Email = 'Email',
  Status = 'Status',
  Actions = 'Actions',
}

export enum UsersAdminAccesors {
  Name = 'name',
  Role = 'role',
  Email = 'email',
  Status = 'status',
  Actions = 'actions',
}

export enum CitiesAdminHeader {
  Name = 'Name',
  Status = 'Status',
  Actions = 'Actions',
}

export enum CitiesAdminAccesors {
  Name = 'name',
  Status = 'status',
  Actions = 'actions',
}

export enum CommunitiesAdminHeader {
  City = 'City',
  Community = 'Community',
  Description = 'Description',
  Actions = 'Actions',
}

export enum CommunitiesAdminAccesors {
  City = 'cityName',
  Community = 'community',
  Description = 'description',
  Actions = 'actions',
}

export enum MembershipsAdminHeader {
  Name = 'Name',
  Email = 'Email',
  Membership = 'Membership',
}

export enum MembershipsAdminAccesors {
  Name = 'name',
  Email = 'email',
  Membership = 'membership',
}
