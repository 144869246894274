import {useDispatch, useSelector} from 'react-redux';
import {setCategories} from '../redux/actions/eventActions';
import {useEffect} from 'react';
import {RootState} from '../redux';

const useCategory = () => {
  const dispatch = useDispatch();
  const {categories} = useSelector((state: RootState) => state.event);

  useEffect(() => {
    listCategories();
  }, []);

  const listCategories = () => {
    dispatch(setCategories());
  };

  return {
    listCategories,
    categories,
  };
};

export default useCategory;
