import React, {FC} from 'react';
import SelectField from '../../../../components/SelectField';
import {TextField} from '../../../../components/TextField';
import useCommunityCity from '../../../../hooks/useComunityCity';
import {useFormikContext} from 'formik';
import {CommunityFormI} from '../../../../models/interfaces/communityInterface';
import './styles.scss';
import TextAreaField from '../../../../components/TextAreaField';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import {useTranslation} from 'react-i18next';

interface Props {
  onClose: () => void;
  onConfirm: (value: CommunityFormI) => void;
}

const CommunityForm: FC<Props> = ({onClose, onConfirm}) => {
  const {cities} = useCommunityCity();
  const {values, setFieldValue} = useFormikContext<CommunityFormI>();
  const {t} = useTranslation('global');

  return (
    <div className="modal-container">
      <ConfirmationModal
        onCancel={onClose}
        onConfirm={() => onConfirm(values)}
        onClose={onClose}>
        <div className="confirmation-container">
          <SelectField
            onChange={v => {
              setFieldValue('cityId', v.value);
            }}
            defaultOption={values.cityId}
            isEdit={values.cityId !== 0}
            label={t('city')}
            placeHolder="City"
            items={cities.map(c => ({label: c.name, value: c.id}))}
          />
          <TextField
            value={values.communityName}
            customStyle={{
              input: {
                width: '600px',
              },
            }}
            handleChange={v => {
              setFieldValue('communityName', v.target.value);
            }}
            placeHolder="Community"
            type="text"
            id="community"
            label={t('labelFields.name')}
          />
          <TextAreaField
            value={values.translationEn}
            onChange={v => {
              setFieldValue('translationEn', v);
            }}
            label={t('descriptionEn')}
          />
          <TextAreaField
            value={values.translationEs}
            onChange={v => {
              setFieldValue('translationEs', v);
            }}
            label={t('descriptionEs')}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default CommunityForm;
