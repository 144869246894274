import {IEventListRequest, IEventUpdateRequest} from '../../models/api/event';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericPost, genericPut} from './genericAPI';

export const getEventsList = (data: IEventListRequest) => {
  const token = store.getState().auth.token;
  return genericPost(endpoints.event.listEvents, token, data);
};

export const updateEvent = (data: IEventUpdateRequest) => {
  const token = store.getState().auth.token;
  return genericPut(
    endpoints.event.updateEvent.replace('{id}', data.eventId.toString()),
    token,
    data,
  );
};
