import {
  CLEAR_MEMBERSHIPS_LIST,
  SET_MEMBERSHIPS_LIST,
  SET_MEMBERSHIPS_LOADING,
  MembershipsActionsTypes,
} from '../types/membershipsTypes';

interface IMembershipState {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  results: {
    id: string;
    name: string;
    lastname: string;
    email: string;
    profileImageUrl: string;
    membership: number;
  }[];
  isLoading: boolean;
}

const initialState: IMembershipState = {
  currentPage: 0,
  pageCount: 0,
  pageSize: 0,
  rowCount: 0,
  firstRowOnPage: 0,
  lastRowOnPage: 0,
  results: [],
  isLoading: false,
};

const membershipsReducer = (
  state = initialState,
  action: MembershipsActionsTypes,
): IMembershipState => {
  switch (action.type) {
    case SET_MEMBERSHIPS_LIST: {
      return {...action.payload, isLoading: false};
    }
    case CLEAR_MEMBERSHIPS_LIST:
      return initialState;
    case SET_MEMBERSHIPS_LOADING:
      return {...state, isLoading: action.payload};
    default:
      return state;
  }
};

export default membershipsReducer;
