import React, {useState} from 'react';
import {ReactComponent as CrossCircleIcon} from '../../assets/icons/red-circle-cross.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/green-tick.svg';
import {ReactComponent as TrashIcon} from '../../assets/icons/trash.svg';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {useTranslation} from 'react-i18next';
import RoundedButton from '../RoundedButton';
import './styles.scss';

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  question?: string;
  detail?: string;
  icon?: EConfirmationModalIcons;
  children?: JSX.Element;
}

const ConfirmationModal = ({
  question,
  detail,
  icon,
  onConfirm,
  onCancel,
  onClose,
  children,
}: IProps) => {
  const {t} = useTranslation('global');
  const [isLoadingMotion, setIsLoadingMotion] = useState<boolean>(false);

  return (
    <div className="confirmation-modal">
      {children ?? (
        <>
          <div className="top-container">
            <div className="close-button" onClick={onClose} />
          </div>
          {icon !== 'none' && (
            <div className="icon">
              {icon === 'check' ? (
                <CheckIcon width={36} height={36} />
              ) : icon === 'trash' ? (
                <TrashIcon width={36} height={36} />
              ) : (
                <CrossCircleIcon width={46} height={46} />
              )}
            </div>
          )}
        </>
      )}
      <span className="question">{question}</span>
      <span className="question-detail">{detail}</span>
      <div className="confirmation-buttons">
        <RoundedButton
          style={{
            marginRight: 8,
            padding: '10px 17px',
            width: 130,
            height: 32,
          }}
          label={t('cancel')}
          color={'blue-outlined'}
          onClick={onCancel}
        />
        <RoundedButton
          color={'blue'}
          isLoading={isLoadingMotion}
          label={t('confirm')}
          style={{
            marginRight: 8,
            padding: '10px 17px',
            width: 130,
            height: 32,
          }}
          onClick={() => {
            setIsLoadingMotion(!isLoadingMotion);
            onConfirm();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmationModal;
