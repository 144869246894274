import React from 'react';
import {EInputsIds} from '../../models/enums/inputsId';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {membershipsTableColumns} from '../../constants/tableColumns';
import {useTranslation} from 'react-i18next';
import HeaderContent from '../../components/HeaderContent';
import InputSearch from '../../components/InputSearch';
import LeftMenu from '../../components/LeftMenu';
import NoResults from '../TaskManagerPage/components/NoResults/index';
import Table from '../../components/Table/index';

import './styles.scss';
import DrawerMenu from '../../components/DrawerMenu';
import useMemberships from '../../hooks/useMemberships';
import Pagination from '../../components/Pagination';

const MembershipsPage = () => {
  const {t} = useTranslation('memberships');
  const {buildedData, onSearchFilter, page, pageCount, setPage, searchFilter} =
    useMemberships();

  return (
    <div data-testid={'user-page'} className="user-page" id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        <HeaderContent
          showFilters={false}
          title={t('title')}
          typeReport={TaskReportType.Memberships}
        />
        <InputSearch
          id={EInputsIds.Search}
          value={searchFilter}
          onChange={onSearchFilter}
        />
        <Table
          columns={membershipsTableColumns}
          data={buildedData}
          noResults={<NoResults text={t('adminUsers:noUsers')} />}
        />
        {buildedData && buildedData.length > 0 && (
          <div className="pagination-wraper">
            <Pagination
              currentPage={page}
              loadPage={setPage}
              pageCount={pageCount}
              setCurrentPage={setPage}
            />
          </div>
        )}
      </div>

      {/* {showConfirmationModal && (
        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          style={confirmModalStyles}>
          <ConfirmationModal
            question={t('adminUsers:confirmationDeleteAdminUserQuestion')}
            detail={t('adminUsers:confirmationMessageDelete')}
            icon={EConfirmationModalIcons.Cross}
            onConfirm={() => handleDelete(adminUserId)}
            onCancel={() => setShowConfirmationModal(false)}
            onClose={() => setShowConfirmationModal(false)}
          />
        </Modal>
      )} */}
    </div>
  );
};

export default MembershipsPage;
