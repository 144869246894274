interface Endpoints {
  auth: AuthEndpoints;
  business: BusinessEndpoints;
  communities: Communities;
  city: CityEndpoint;
  event: EventEndpoints;
  post: Post;
  signalR: SignalRConnections;
  tasks: TaskEndpoints;
  tiers: TierEndpoints;
  user: UserEndpoints;
  category: CategoryEndpoints;
}

interface EventEndpoints {
  listEvents: string;
  updateEvent: string;
}
interface AuthEndpoints {
  authenticate: string;
  forgotPasswordByEmail: string;
  forgotPasswordByPhone: string;
  resetPasswordByCode: string;
  resetPasswordByPhone: string;
  userInformation: string;
  validPhoneCode: string;
  validResetPasswordCode: string;
}

interface TaskEndpoints {
  getUserToVerfy: string;
  listPendingReport: string;
  listPendingTasks: string;
  listUserReport: string;
  pendingCounts: string;
  updateAdStatus: string;
  updateBusinessStatus: string;
  updateCommentReportStatus: string;
  updateContactUsStatus: string;
  updateDealStatus: string;
  updatePostReportStatus: string;
  updateStatus: string;
}

interface SignalRConnections {
  task: string;
}

interface BusinessEndpoints {
  businessInformation: string;
}

interface TierEndpoints {
  listPackages: string;
  createPackage: string;
  deletePackage: string;
  updatePackage: string;
}
interface CityEndpoint {
  list: string;
  create: string;
  update: string;
  delete: string;
}
interface Communities {
  list: string;
  create: string;
  update: string;
  delete: string;
}
interface Post {
  listPostComments: string;
  list: string;
  delete: string;
  publishMultimedia: string;
  publishText: string;
  commentPost: string;
  replyCommentPost: string;
  listReplies: string;
}

interface UserEndpoints {
  createNewAdminUser: string;
  deleteAdminUser: string;
  editAdminUserRole: string;
  listAdminRoles: string;
  listAdminUsers: string;
  listManaNews: string;
  search: string;
  updateMembership: string;
}

interface CategoryEndpoints {
  listCategories: string;
}

const endpoints: Endpoints = {
  auth: {
    authenticate: 'authenticateAdminTool',
    forgotPasswordByEmail: 'forgot-password-by-code',
    forgotPasswordByPhone: 'forgot-password-by-phone',
    resetPasswordByCode: 'reset-password-by-code',
    resetPasswordByPhone: 'reset-password-by-phone',
    userInformation: 'user-information',
    validPhoneCode: 'validate-phone-code',
    validResetPasswordCode: 'validate-reset-password-code',
  },
  tasks: {
    getUserToVerfy: 'tasks/user-confirmation/{id}',
    listPendingReport: 'tasks/pending-reports',
    listPendingTasks: 'tasks/pending',
    listUserReport: 'tasks/user-reports',
    pendingCounts: 'tasks/pending-count',
    updateAdStatus: 'tasks/ad-confirmation/state',
    updateBusinessStatus: 'tasks/business-update/state',
    updateCommentReportStatus: 'tasks/comment-report/state',
    updateContactUsStatus: 'tasks/contact-us-request/state',
    updateDealStatus: 'tasks/deal-confirmation/state',
    updatePostReportStatus: 'tasks/post-report/state',
    updateStatus: 'tasks/user-confirmation/state',
  },
  signalR: {
    task: `${process.env.REACT_APP_BASE_URL}/tasksHub`,
  },
  business: {
    businessInformation: 'business',
  },
  tiers: {
    listPackages: 'package',
    createPackage: 'package/create',
    deletePackage: 'package/delete/{id}',
    updatePackage: 'package/update/{id}',
  },
  city: {
    list: 'communitycity/list-communitycities',
    create: 'communitycity/create',
    update: 'communitycity/update/{id}',
    delete: 'communitycity/delete/{id}',
  },

  communities: {
    list: 'community/list-communities',
    create: 'community/create',
    update: 'community/update/{id}',
    delete: 'community/delete/{id}',
  },
  post: {
    listPostComments: 'post/list-comments',
    list: 'post/list',
    delete: 'post/delete/',
    publishMultimedia: 'publish-multimedia',
    publishText: 'publish-text',
    commentPost: 'comment',
    replyCommentPost: 'reply-comment',
    listReplies: 'post/list-child-comments',
  },
  user: {
    createNewAdminUser: 'user/create-admin-user',
    deleteAdminUser: 'user/delete-admin-user/?userId={id}',
    editAdminUserRole: 'user/update-admin-user-role',
    listAdminRoles: 'user/get-admin-roles',
    listAdminUsers: 'user/get-admin-users',
    listManaNews: 'user/get-mana-news-users',
    search: 'user/search',
    updateMembership: 'user/update-user-membership',
  },
  event: {
    listEvents: 'event/list-events',
    updateEvent: 'event/update/{id}',
  },
  category: {
    listCategories: 'business/list-categories',
  },
};

export default endpoints;
