import React, {useEffect, useState} from 'react';
import LeftMenu from '../../components/LeftMenu';
import HeaderContent from '../../components/HeaderContent';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import './styles.scss';
import Table from '../../components/Table';
import {citiesTableColumns} from '../../constants/tableColumns';
import NoResults from '../TaskManagerPage/components/NoResults';
import Tag from '../../components/Tag';
import {getTypeStatusTag} from '../../constants/getTexts';
import {UserStatus} from '../../models/enums/userStauts';

import ModalForm from '../../components/ModalForm';
import Modal from 'react-modal';
import {generalModalStyles} from '../../constants/styles';

import {CitiDataI, CityFormI} from '../../models/interfaces/cityInterface';
import Actions from '../../components/Actions';
import {Formik} from 'formik';
import CityForm from './components/CityForm';
import useCommunityCity from '../../hooks/useComunityCity';
import ConfirmationModal from '../../components/ConfirmationModal';
import {useTranslation} from 'react-i18next';
import DrawerMenu from '../../components/DrawerMenu';

const initialFormValue = {
  name: '',
  comingSoon: true,
  id: 0,
  isDeleted: false,
};

const CitiesPage = () => {
  const [data, setData] = useState<CitiDataI[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [initialValues, setInitialValues] =
    useState<CityFormI>(initialFormValue);
  const [editionMode, setEditionMode] = useState(false);

  const {cities, createCity, updateCity, deleteCity} = useCommunityCity();
  const {t} = useTranslation('communityCities');

  const handleEditCity = (cityToEdit: CityFormI) => {
    updateCity(cityToEdit);
    setIsModalOpen(false);
    setInitialValues(initialFormValue);
  };

  const handleCreateCity = (values: CityFormI) => {
    createCity({comingSoon: values.comingSoon, name: values.name});
    setInitialValues(initialFormValue);
    setIsModalOpen(false);
  };

  const handleDeleteCity = async () => {
    await deleteCity(initialValues.id);
    setInitialValues(initialFormValue);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    const buildedData = cities
      ? cities.map(c => {
          const tagStatus = getTypeStatusTag(
            c.comingSoon ? UserStatus.Inactive : UserStatus.Active,
          );
          return {
            name: c.name,
            status: (
              <Tag
                text={tagStatus.text}
                backgroundColor={tagStatus.background}
                color={tagStatus.textcolor}
              />
            ),
            actions: (
              <Actions
                actionsList={[
                  {
                    label: 'global:edit',
                    action: () => {
                      setEditionMode(true);
                      setInitialValues({
                        name: c.name,
                        comingSoon: c.comingSoon,
                        id: c.id,
                        isDeleted: c.isDeleted,
                      });
                      setIsModalOpen(true);
                    },
                  },
                  {
                    label: 'global:delete',
                    action: () => {
                      setInitialValues({
                        name: c.name,
                        comingSoon: c.comingSoon,
                        id: c.id,
                        isDeleted: c.isDeleted,
                      });
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            ),
          };
        })
      : [];
    setData(buildedData);
  }, [cities]);

  return (
    <div className="user-page" id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        <HeaderContent
          title={t('cities')}
          showFilters={false}
          typeReport={TaskReportType.Cities}
          createNew
          onClick={() => {
            setEditionMode(false);
            setIsModalOpen(true);
          }}
        />
        <div className="table">
          <Table
            columns={citiesTableColumns}
            data={data}
            noResults={<NoResults text={t('noCities')} />}
          />
        </div>

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            style={{
              ...generalModalStyles,
              content: {...generalModalStyles.content, width: '450px'},
            }}>
            <Formik initialValues={initialValues} onSubmit={handleCreateCity}>
              <ModalForm
                title={t('createCity')}
                handleCloseModal={() => {
                  setInitialValues(initialFormValue);
                  setIsModalOpen(false);
                }}
                form={
                  <CityForm
                    onClose={() => {
                      setInitialValues(initialFormValue);
                      setIsModalOpen(false);
                    }}
                    onConfirm={editionMode ? handleEditCity : handleCreateCity}
                  />
                }
              />
            </Formik>
          </Modal>
        )}

        <Modal
          isOpen={deleteModalOpen}
          style={{
            ...generalModalStyles,
            content: {...generalModalStyles.content, width: '450px'},
          }}>
          <ConfirmationModal
            onCancel={() => setDeleteModalOpen(false)}
            onConfirm={handleDeleteCity}
            detail={t('remove').replace('{name}', initialValues.name)}
            onClose={() => setDeleteModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default CitiesPage;
