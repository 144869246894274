import React, {useState} from 'react';
import Dropdown from '../../../../components/Dropdown';
import {ReactComponent as ChevronDown} from '../../../../assets/icons/chevron-down.svg';
import {useTranslation} from 'react-i18next';
import {showPopup} from '../../../../redux/actions/uiActions';
import {useDispatch} from 'react-redux';
import {EGeneralColors} from '../../../../models/enums/colors';
import './styles.scss';
import useMemberships from '../../../../hooks/useMemberships';

interface IProps {
  userId: string;
  selectedId: number;
}
const MembershipSelector = ({selectedId, userId}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(selectedId);
  const {updateMembership} = useMemberships();

  const [pageX, setPageX] = React.useState<number>(0);
  const [pageY, setPageY] = React.useState<number>(0);

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const memberships = [
    {id: 1, name: 'Basic'},
    {id: 2, name: 'Local'},
    {id: 3, name: 'VIP'},
  ];

  const handleSelectMembership = async (id: number) => {
    setIsModalOpen(false);
    setSelectedMembership(id);
    await updateMembership(userId, id);
    dispatch(
      showPopup(t('events:notificationMessageChanged'), 3000, 'success'),
    );
  };

  const handleOnClick = (event: any) => {
    setIsModalOpen(true);
    setPageX(event.clientX);
    setPageY(event.clientY);
  };

  return (
    <div className="table-buttons">
      <div className="community-wraper">
        {memberships[selectedMembership ? selectedMembership - 1 : 0].name}
      </div>
      <span className="community-selector" onClick={handleOnClick}>
        <div className="chevron">
          <ChevronDown fill={EGeneralColors.colorBlueLink} />
        </div>
      </span>

      {isModalOpen && (
        <Dropdown
          isOpen={isModalOpen}
          options={memberships.map(c => (
            <p
              className="dropdown-item"
              key={c.id}
              onClick={() => handleSelectMembership(c.id)}>
              {c.name}
            </p>
          ))}
          setIsOpen={setIsModalOpen}
          pageX={pageX - 120}
          pageY={pageY + 16}
        />
      )}
    </div>
  );
};

export default MembershipSelector;
