import React, {useEffect, useState} from 'react';
import LeftMenu from '../../components/LeftMenu';
import HeaderContent from '../../components/HeaderContent';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import './styles.scss';
import Table from '../../components/Table';
import {communitiesTableColumns} from '../../constants/tableColumns';
import NoResults from '../TaskManagerPage/components/NoResults';
import {
  CommunityDataI,
  CommunityFormI,
} from '../../models/interfaces/communityInterface';
import Actions from '../../components/Actions';
import ModalForm from '../../components/ModalForm';
import Modal from 'react-modal';
import {generalModalStyles} from '../../constants/styles';
import CommunityForm from './components/CommunityForm';
import useCommunityCity from '../../hooks/useComunityCity';
import {Formik} from 'formik';
import {TranslationI} from '../../models/api/comunity';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from '../../components/ConfirmationModal';
import User from '../../assets/icons/menu-users.svg';
import DrawerMenu from '../../components/DrawerMenu';

const initialFormValue: CommunityFormI = {
  cityId: 0,
  communityId: 0,
  communityName: '',
  translationEn: '',
  translationEs: '',
};

const CommunitiesPage = () => {
  const [data, setData] = useState<CommunityDataI[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormValue);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const {
    communities,
    createCommunity,
    deleteCommunity,
    getCityName,
    updateCommunity,
  } = useCommunityCity();
  const {t, i18n} = useTranslation('communityCities');

  const getTranslation = (translations: TranslationI[], code?: string) => {
    return translations.find(t =>
      code
        ? t.language.languageCode === code
        : t.language.languageCode === i18n.resolvedLanguage,
    );
  };

  useEffect(() => {
    const buildedData = communities.map(c => {
      return {
        cityName: c.cityName ?? getCityName(c.communityCityId),
        description: getTranslation(c.translations)?.value ?? '',
        community: c.name,
        actions: (
          <Actions
            actionsList={[
              {
                label: 'global:edit',
                action: () => {
                  setEditMode(true);
                  setFormData({
                    communityId: c.id,
                    cityId: c.communityCityId,
                    communityName: c.name,
                    translationEn:
                      getTranslation(c.translations, 'en')?.value ?? '',
                    translationEs:
                      getTranslation(c.translations, 'es')?.value ?? '',
                  });
                  setIsModalOpen(true);
                },
              },
              {
                label: 'global:delete',
                action: () => {
                  setDeleteModalOpen(true);
                  setFormData({
                    ...formData,
                    communityId: c.id,
                    communityName: c.name,
                  });
                },
              },
            ]}
          />
        ),
      };
    });
    setData(buildedData);
  }, [communities]);

  useEffect(() => {
    if (!isModalOpen) {
      setFormData(initialFormValue);
      setEditMode(false);
    }
  }, [isModalOpen]);

  const handleOnClose = () => {
    setEditMode(false);
    setDeleteModalOpen(false);
    setFormData(initialFormValue);
  };

  const handleCreateCommunity = (values: CommunityFormI) => {
    setFormData(initialFormValue);
    createCommunity({
      communityCityId: values.cityId,
      name: values.communityName,
      translationEn: values.translationEn,
      translationEs: values.translationEs,
    });
    setIsModalOpen(false);
  };

  const handleUpdateCommunity = (values: CommunityFormI) => {
    updateCommunity({
      id: values.communityId,
      communityCityId: values.cityId,
      name: values.communityName,
      translationEn: values.translationEn,
      translationEs: values.translationEs,
    });
    setIsModalOpen(false);
  };

  const handleDeleteCommunity = async () => {
    if (formData.communityId) {
      await deleteCommunity(formData.communityId);
      setFormData(initialFormValue);
      setDeleteModalOpen(false);
    }
  };

  return (
    <div className="user-page" id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        <HeaderContent
          title={t('communities')}
          showFilters={false}
          typeReport={TaskReportType.Communities}
          createNew
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
        <div className="table">
          <Table
            columns={communitiesTableColumns}
            data={data}
            noResults={<NoResults />}
          />
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={{
            ...generalModalStyles,
            content: {
              ...generalModalStyles.content,
              height: '80%',
              width: '50%',
            },
          }}>
          <Formik initialValues={formData} onSubmit={v => console.log(v)}>
            <ModalForm
              title={t('createCommunity')}
              icon={<img src={User} alt="communities" height={25} width={33} />}
              handleCloseModal={() => setIsModalOpen(false)}
              form={
                <CommunityForm
                  onConfirm={
                    editMode ? handleUpdateCommunity : handleCreateCommunity
                  }
                  onClose={() => setIsModalOpen(false)}
                />
              }
            />
          </Formik>
        </Modal>

        <Modal
          isOpen={deleteModalOpen}
          style={{
            ...generalModalStyles,
            content: {...generalModalStyles.content, width: '450px'},
          }}>
          <ConfirmationModal
            onCancel={handleOnClose}
            onConfirm={handleDeleteCommunity}
            detail={t('remove').replace('{name}', formData.communityName)}
            onClose={handleOnClose}
          />
        </Modal>
      </div>
    </div>
  );
};

export default CommunitiesPage;
