import {IMembershipsResponse} from '../../models/api/memberships';

export const SET_MEMBERSHIPS_LIST = 'SET_MEMBERSHIPS_LIST';
export const CLEAR_MEMBERSHIPS_LIST = 'CLEAR_MEMBERSHIPS_LIST';
export const SET_MEMBERSHIPS_LOADING = 'SET_MEMBERSHIPS_LOADING';

interface ISetMembershipsList {
  type: typeof SET_MEMBERSHIPS_LIST;
  payload: IMembershipsResponse;
}
interface IClearMembershipsList {
  type: typeof CLEAR_MEMBERSHIPS_LIST;
}
interface ISetMembershipsLoading {
  type: typeof SET_MEMBERSHIPS_LOADING;
  payload: boolean;
}

export type MembershipsActionsTypes =
  | ISetMembershipsList
  | IClearMembershipsList
  | ISetMembershipsLoading;
