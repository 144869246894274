import React, {useEffect, useState} from 'react';
import {buildReportData, getUserReportType} from './data';
import {capitalizeFirstLetter} from '../../utils/capitalizeFirstLetter';
import {EConfirmationModalIcons} from '../../models/enums/confirmationModal';
import {EGeneralColors} from '../../models/enums/colors';
import {ellipsisText} from '../../utils/ellipsisText';
import {generalModalStyles, confirmModalStyles} from '../../constants/styles';
import {getUserReports} from '../../services/api/taskAPI';
import {ICurrentSort} from '../../models/interfaces/table';
import {IReportData} from '../../models/interfaces/taskData';
import {IReportListRow, useReportList} from '../../hooks/useReportList';
import {PendingTaskOrderBy} from '../../models/enums/pendingTaskOrderBy';
import {reportsTableColumns} from '../../constants/tableColumns';
import {ReportTaskState} from '../../models/enums/reportTaskState';
import {TaskManagerReportStatus} from '../../models/enums/taskStatus';
import {useReportActions} from '../../hooks/useReportActions';
import {useTranslation} from 'react-i18next';
import {
  TaskManagerTaskType,
  TaskReportType,
} from '../../models/enums/taskManagerTaskType';
import {
  ApprovalOperation,
  EColorsOperationBtns,
} from '../../models/enums/approval';
import Actions from '../TaskManagerPage/components/Actions';
import AppliedFilters from '../../components/HeaderContent/AppliedFilters';
import Approval from '../../components/Approval';
import ConfirmationModal from '../../components/ConfirmationModal';
import formatDate from '../../utils/formatDate';
import HeaderContent from '../../components/HeaderContent';
import LeftMenu from '../../components/LeftMenu';
import Modal from 'react-modal';
import NoResults from '../TaskManagerPage/components/NoResults';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import Tag from '../../components/Tag';
import './styles.scss';
import DrawerMenu from '../../components/DrawerMenu';

const ReportingPage = () => {
  // Hooks
  const {t} = useTranslation();
  const {
    list,
    pageCount,
    currentPage,
    appliedFilters,
    setCurrentPage,
    loadPage,
    onSort,
    onFilter,
  } = useReportList();
  const {onContact, onUpdateStatus} = useReportActions();

  // Data
  const allowedSortBy = ['reportType', 'date', 'status'];

  // States
  const [data, setData] = useState<IReportData[] | []>([]);
  const [currentSort, setCurrentSort] = useState<ICurrentSort>({
    accessor: '',
    direction: 'ascending',
  });
  const [currentTaskTypeSelected, setCurrentTaskTypeSelected] =
    useState<TaskManagerTaskType | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<IReportListRow>();
  const [reportState, setReportState] = useState<ReportTaskState | null>(null);
  const [userReports, setUserReports] = useState<any[]>([]);

  // Effects
  useEffect(() => {
    const record: IReportData[] = [];

    list.forEach(report => {
      let account = '';
      let type = '';

      switch (report.taskType) {
        case TaskManagerTaskType.CommentReportRequest:
          type = `${t('global:message')} (${t(
            getUserReportType(report.commentReport?.type, t),
          )})`;
          account = ellipsisText(
            report.comment ? report.comment.publisherEmail : report.fullname,
            32,
          );
          break;

        case TaskManagerTaskType.PostReportRequest:
          type = `${t('global:post')} (${t(
            getUserReportType(report.postReport?.type, t),
          )})`;
          account = ellipsisText(
            report.post ? report.post.publisherEmail : report.fullname,
            32,
          );
          break;

        default:
          break;
      }

      record.push({
        reportedAccount: account,
        reportType: type,
        date: formatDate(report.dateRequested),
        status: (
          <div className="tag-container">
            <Tag
              text={capitalizeFirstLetter(report.status)}
              color={
                report.status === TaskManagerReportStatus[1]
                  ? EGeneralColors.colorRedStatusTask
                  : EGeneralColors.colorGreenStatusReport
              }
              backgroundColor={
                report.status === TaskManagerReportStatus[1]
                  ? EGeneralColors.colorRedSoftStatusReport
                  : EGeneralColors.colorGreenSoftStatusReport
              }
            />
          </div>
        ),
        actions: (
          <Actions
            ad={report.ad ? report.ad : null}
            business={null}
            contactUs={null}
            deal={report.deal ? report.deal : null}
            handleConfirmationData={() => false}
            handleOpenDetails={handleOpenDetails}
            showQuickActions={false}
            taskId={report.id}
            taskType={report.taskType}
            vendorId={report.vendorId && report.vendorId}
          />
        ),
      });
    });
    setData(record);
  }, [list]);

  // Utils - Handlers
  const handleOpenDetails = async (id: number, type: TaskManagerTaskType) => {
    try {
      //TODO: implement scroll pagination
      const selected = list.find(i => i.id === id);
      if (selected) {
        const response = await getUserReports({
          page: 1,
          perPage: 3,
          reportedUserId:
            (selected.taskType === TaskManagerTaskType.CommentReportRequest
              ? selected.comment?.publisherId
              : selected.post?.publisherId) || '',
        });

        setUserReports(response.data);
        setCurrentTaskTypeSelected(type);
        setSelectedTask(selected);
        setModalIsOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmTask = async () => {
    const reportId =
      currentTaskTypeSelected === TaskManagerTaskType.CommentReportRequest
        ? selectedTask?.commentReport?.id || 0
        : selectedTask?.postReport?.id || 0;
    if (
      reportId &&
      selectedTask &&
      selectedTask.id &&
      currentTaskTypeSelected &&
      reportState
    ) {
      await onUpdateStatus(reportId, currentTaskTypeSelected, reportState);
    }
    setModalIsOpen(false);
    setConfirmationIsOpen(false);
  };

  const handleOnAscendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'ascending'});
    switch (accessor) {
      case 'reportType':
        onSort(PendingTaskOrderBy.TaskTypeAscending);
        break;
      case 'date':
        onSort(PendingTaskOrderBy.DateAscending);
        break;
      case 'status':
        onSort(PendingTaskOrderBy.StatusAscending);
        break;

      default:
        break;
    }
  };

  const handleOnDescendingSort = (accessor: string) => {
    setCurrentSort({accessor: accessor, direction: 'descending'});
    switch (accessor) {
      case 'reportType':
        onSort(PendingTaskOrderBy.TaskTypeDescending);
        break;
      case 'date':
        onSort(PendingTaskOrderBy.DateDescending);
        break;
      case 'status':
        onSort(PendingTaskOrderBy.StatusDescending);
        break;

      default:
        break;
    }
  };

  const getConfirmationMessage = () => {
    if (ReportTaskState.BlockContentAccount === reportState) {
      return t('taskManagement:revokeConfirmationMessage');
    }
    return t('taskManagement:ignoreConfirmationMessage');
  };

  return (
    <div data-testid={'reports-page'} className="reports-page" id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        <HeaderContent
          onFilter={onFilter}
          appliedFilters={appliedFilters}
          showFilters
          title={t('menu:reports')}
          titleFilter={t('global:filterReports')}
          typeReport={TaskReportType.Report}
        />

        {appliedFilters && Object.keys(appliedFilters).length > 0 && (
          <AppliedFilters onFilter={onFilter} appliedFilters={appliedFilters} />
        )}

        <Table
          columns={reportsTableColumns}
          data={data}
          handleOnAscendingSort={handleOnAscendingSort}
          handleOnDescendingSort={handleOnDescendingSort}
          currentSort={currentSort}
          allowedSortBy={allowedSortBy}
          noResults={<NoResults text={t('reports:noReport')} />}
        />

        {data && data.length > 0 && (
          <Pagination
            pageCount={pageCount}
            loadPage={loadPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>

      {modalIsOpen &&
        selectedTask &&
        (currentTaskTypeSelected === TaskManagerTaskType.PostReportRequest ||
          currentTaskTypeSelected ===
            TaskManagerTaskType.CommentReportRequest) && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={generalModalStyles}>
            <Approval
              data={buildReportData(selectedTask, userReports, t)}
              closeModal={() => setModalIsOpen(false)}
              onContact={onContact}
              comment={selectedTask?.comment}
              post={selectedTask.post}
              handleReportConfirmation={status => {
                setReportState(status);
                setModalIsOpen(true);
                setConfirmationIsOpen(true);
              }}
              taskType={currentTaskTypeSelected}
              operations={[
                {
                  type: ApprovalOperation.revokeAccess,
                  color: EColorsOperationBtns.RedOutlined,
                  label: t('taskManagement:revokeButtonLabel', {
                    businessName:
                      selectedTask.taskType ===
                      TaskManagerTaskType.CommentReportRequest
                        ? selectedTask.comment?.publisherFullname || ''
                        : selectedTask.post?.publisherFullname || '',
                  }),
                },
                {
                  type: ApprovalOperation.ignore,
                  color: EColorsOperationBtns.BlueOutlined,
                  label: t('taskManagement:ignoreReportButtonLabel'),
                },
                {
                  type: ApprovalOperation.contact,
                  color: EColorsOperationBtns.Blue,
                  label: t('taskManagement:contactBusinessButtonLabel', {
                    businessName:
                      selectedTask.taskType ===
                      TaskManagerTaskType.CommentReportRequest
                        ? selectedTask.comment?.publisherFullname || ''
                        : selectedTask.post?.publisherFullname || '',
                  }),
                },
              ]}
            />
          </Modal>
        )}

      {confirmationIsOpen && (
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={() => setConfirmationIsOpen(false)}
          style={confirmModalStyles}>
          <ConfirmationModal
            question={t('taskManagement:confirmationTaskQuestion')}
            detail={getConfirmationMessage()}
            icon={EConfirmationModalIcons.None}
            onConfirm={() => handleConfirmTask()}
            onCancel={() => setConfirmationIsOpen(false)}
            onClose={() => setConfirmationIsOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default ReportingPage;
