import {CityI} from '../../models/api/city';
import {CommunityI} from '../../models/api/comunity';
import {
  SET_CITIES_LIST,
  SET_COMMUNITIES_LIST,
} from '../types/communityCity.types';

export const setCommunityList = (list: CommunityI[]) => ({
  type: SET_COMMUNITIES_LIST,
  payload: list,
});

export const setCityList = (list: CityI[]) => ({
  type: SET_CITIES_LIST,
  payload: list,
});
