import React, {useCallback, useEffect, useState} from 'react';
import {IMembershipsBuidedTabledata} from '../models/api/memberships';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearMembershipsList,
  getAndSaveMembershipList,
  updateUser,
} from '../redux/actions/membershipsActions';
import {RootState} from '../redux';
import MembershipSelector from '../pages/MembershipsPage/Components/MembershipSelector';
import {updateUserMembership} from '../services/api/membershipServices';

const MEMBERSHIPS_PER_PAGE = 10;

const useMemberships = () => {
  const [buildedData, setBuildedData] = useState<IMembershipsBuidedTabledata[]>(
    [],
  );
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useDispatch();
  const {currentPage, isLoading, pageCount, results} = useSelector(
    (state: RootState) => state.memberships,
  );
  const [page, setPage] = useState(
    currentPage === 0 ? currentPage : currentPage - 1,
  );

  const handleLoadData = () => {
    dispatch(
      getAndSaveMembershipList({
        page: page + 1,
        perPage: MEMBERSHIPS_PER_PAGE,
        term: searchFilter,
      }),
    );
  };

  const loadInitialData = () => {
    dispatch(
      getAndSaveMembershipList({
        page: 0,
        perPage: MEMBERSHIPS_PER_PAGE,
        term: searchFilter,
      }),
    );
  };

  const handleSearch = (search: string) => {
    setSearchFilter(search);
  };

  const onSearchFilter = useCallback((e: any) => {
    handleSearch(e.target.value);
  }, []);

  const updateMembership = async (userId: string, membershipId: number) => {
    try {
      const response = await updateUserMembership(userId, membershipId);
      if (response && response.data) {
        dispatch(updateUser(response.data.id, response.data.membership));
      }
    } catch (e: any) {
      console.error('Error on update user membership: ', e);
    }
  };

  const handleSetTableData = () => {
    const buildData: IMembershipsBuidedTabledata[] = results.map(m => {
      return {
        membership: (
          <MembershipSelector userId={m.id} selectedId={m.membership} />
        ),
        email: m.email,
        name: m.name,
      };
    });
    setBuildedData(buildData);
  };

  useEffect(() => {
    handleSetTableData();
  }, [results]);

  useEffect(() => {
    setPage(0);
    handleLoadData();
  }, [searchFilter]);

  useEffect(() => {
    if (page > -1) {
      handleLoadData();
    }

    if (currentPage > pageCount) {
      dispatch(clearMembershipsList());
      loadInitialData();
    }
  }, [page]);

  useEffect(() => {
    loadInitialData();
  }, []);

  return {
    onSearchFilter,
    buildedData,
    isLoading,
    pageCount,
    page,
    setPage,
    searchFilter,
    updateMembership,
  };
};

export default useMemberships;
