import {CategoryI} from '../../models/api/category';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet} from './genericAPI';

export const getCategories = (): Promise<{data: CategoryI[]}> => {
  const token = store.getState().auth.token;

  return genericGet(endpoints.category.listCategories, token);
};
