export default {
  events: '/events',
  forgotPassword: '/forgot-password',
  home: '/dashboard',
  manaNews: '/mana-news',
  newPassword: '/new-password',
  reports: '/reports',
  root: '/',
  taskManager: '/task-manager',
  tiers: '/tiers',
  users: '/users',
  memberships: '/memberships',
  verificationCode: '/verification-code',
  communities: '/communities',
  cities: '/cities',
};
