import {CreateCommunityI, CommunityI} from '../../models/api/comunity';
import {store} from '../../redux';
import {parsePublishPostToFormData} from '../../utils/parseToFormData';
import endpoints from './endpoints';
import {genericGet, genericPost} from './genericAPI';

export const getCommunitiesListService = (): Promise<{data: CommunityI[]}> => {
  const {token} = store.getState().auth;

  return genericGet(endpoints.communities.list, token);
};

export const createCommunityService = (community: CreateCommunityI) => {
  const {token} = store.getState().auth;
  const fdata = parsePublishPostToFormData(community);

  const translations = [
    {
      language: {name: 'English', languageCode: 'en'},
      value: community.translationEn,
      languageId: 1,
    },
    {
      language: {name: 'Español', languageCode: 'es'},
      value: community.translationEs,
      languageId: 2,
    },
  ];

  fdata.append('TranslationsJson', JSON.stringify(translations));

  return genericPost(endpoints.communities.create, token, fdata);
};

export const updateCommunityService = (communityUpdated: CreateCommunityI) => {
  const {token} = store.getState().auth;
  const fdata = parsePublishPostToFormData(communityUpdated);

  const translations = [
    {
      language: {name: 'English', languageCode: 'en'},
      value: communityUpdated.translationEn,
      languageId: 1,
    },
    {
      language: {name: 'Español', languageCode: 'es'},
      value: communityUpdated.translationEs,
      languageId: 2,
    },
  ];

  fdata.append('TranslationsJson', JSON.stringify(translations));

  return genericPost(
    endpoints.communities.update.replace(
      '{id}',
      communityUpdated.id ? communityUpdated.id.toString() : '',
    ),
    token,
    fdata,
  );
};

export const deleteCommunityService = (communityId: number) => {
  const {token} = store.getState().auth;

  return genericPost(
    endpoints.communities.delete.replace('{id}', communityId.toString()),
    token,
  );
};
