import React, {useEffect, useState} from 'react';
import {EventOrderBy} from '../../models/enums/eventOrderBy';
import {eventsTableColumns} from '../../constants/tableColumns';
import {ICurrentSort} from '../../models/interfaces/table';
import {RootState} from '../../redux';
import {TaskReportType} from '../../models/enums/taskManagerTaskType';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CommunitySelector from './components/CommunitySelector';
import HeaderContent from '../../components/HeaderContent';
import LeftMenu from '../../components/LeftMenu';
import moment from 'moment';
import NoResults from '../TaskManagerPage/components/NoResults';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import useEventsList from '../../hooks/useEventsList';
import './styles.scss';
import useCategory from '../../hooks/useCategory';
import CategorySelector from './components/CategorySelector';
import DrawerMenu from '../../components/DrawerMenu';

const EventsPage = () => {
  const {t} = useTranslation();
  const dateFormat = 'D MMM - h:mm A';
  const [data, setData] = useState<any[]>([]);
  const [currentSort, setCurrentSort] = useState<ICurrentSort>();
  const {communities} = useSelector((state: RootState) => state.user);

  const {
    list,
    onSort,
    currentPage,
    setCurrentPage,
    pageCount,
    loadPage,
    handleChangeCommunity,
    handleChangeCategory,
  } = useEventsList();
  // const {i18n} = useTranslation();

  const {categories} = useCategory();
  console.log('categories', categories);

  useEffect(() => {
    const formatedData = list.map(e => {
      let formatLocation = '';
      if (e.eventVenue) {
        formatLocation = `${e.eventVenue.streetNumber ?? ''} ${
          e.eventVenue.street ?? ''
        }${e.eventVenue.streetNumber || e.eventVenue.street ? ',' : ''}  ${
          e.eventVenue.state
        }`;
      }
      const eventCategory =
        e.subCategories.length > 0 ? e.subCategories[0].id : null;

      return {
        name: e.name,
        startDate: moment(e.startDate).format(dateFormat),
        location: formatLocation,
        communityId: (
          <CommunitySelector
            communities={communities ?? []}
            selectedId={e.communityId}
            handleChangeCommunity={(communityId, eventId) => {
              handleChangeCommunity(communityId, eventId, eventCategory);
            }}
            eventId={e.id}
          />
        ),
        category: (
          <CategorySelector
            categories={categories}
            handleChangeCategory={id => {
              handleChangeCategory(id, e.id, e.communityId ?? null);
            }}
            selectedId={
              e.subCategories.length > 0 ? e.subCategories[0].id : undefined
            }
          />
        ),
      };
    });
    setData(formatedData);
  }, [list]);

  const handleOnAscendingSort = (accessor: string) => {
    setCurrentSort({accessor, direction: 'ascending'});
    onSort(EventOrderBy.DateAscending);
  };

  const handleOnDescendingSort = (accessor: string) => {
    setCurrentSort({accessor, direction: 'descending'});
    onSort(EventOrderBy.DateDescending);
  };

  return (
    <div data-testid={'events-page'} className="events-page" id="layout">
      <LeftMenu />
      <DrawerMenu />
      <div className="page-content">
        <HeaderContent
          title={t('menu:events')}
          showFilters={false}
          typeReport={TaskReportType.Event}
        />
        <Table
          allowedSortBy={['startDate']}
          columns={eventsTableColumns}
          currentSort={currentSort}
          data={data}
          handleOnAscendingSort={handleOnAscendingSort}
          handleOnDescendingSort={handleOnDescendingSort}
          noResults={<NoResults text={t('events:noEvent')} />}
        />

        {data && data.length > 0 && (
          <div className="pagination-wraper">
            <Pagination
              currentPage={currentPage}
              loadPage={loadPage}
              pageCount={pageCount}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsPage;
