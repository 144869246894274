import React, {useState} from 'react';
import './styles.scss';
import {EMenuToggle} from '../../models/enums/menu';
import {ReactComponent as LogoutIcon} from '../../assets/icons/log-out.svg';
import {ReactComponent as HamburgerIcon} from '../../assets/icons/lines.svg';

import {useDispatch} from 'react-redux';
import {logoutUser} from '../../redux/actions/authActions';
import Content from '../LeftMenu/components/Content';
import SmallLogoIcon from '../../assets/images/SmallLogo.png';

const DrawerMenu = () => {
  const [isOpen, setisOpen] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <nav>
        <div
          onClick={() => {
            setisOpen(!isOpen);
          }}>
          <HamburgerIcon width={20} height={20} />
        </div>
        <img src={SmallLogoIcon} width={40} height={40} alt="logo" />
        <div className="logout" onClick={() => dispatch(logoutUser())}>
          <LogoutIcon />
        </div>
      </nav>
      {isOpen && (
        <div
          className="drawer-background"
          onClick={() => {
            setisOpen(!isOpen);
          }}>
          <div className="drawer-content">
            <Content menuIsOpen={EMenuToggle.Open} />
          </div>
        </div>
      )}
    </>
  );
};

export default DrawerMenu;
