import {CreateCityI, CityI} from '../../models/api/city';
import {store} from '../../redux';
import endpoints from './endpoints';
import {genericGet, genericPost} from './genericAPI';

export const getCitiesListService = (): Promise<{data: CityI[]}> => {
  const {token} = store.getState().auth;

  return genericGet(endpoints.city.list, token);
};

export const createCityService = (city: CreateCityI) => {
  const {token} = store.getState().auth;

  return genericPost(endpoints.city.create, token, city);
};

export const updateCityService = (cityUpdated: CityI) => {
  const {token} = store.getState().auth;

  return genericPost(
    endpoints.city.update.replace('{id}', cityUpdated.id.toString()),
    token,
    cityUpdated,
  );
};

export const deleteCityService = (cityId: number) => {
  const {token} = store.getState().auth;

  return genericPost(
    endpoints.city.delete.replace('{id}', cityId.toString()),
    token,
  );
};
