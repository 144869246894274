import React, {useState} from 'react';
import {AppState} from '../../redux/store';
import {
  ECssClassBuildData,
  EWeekdays,
  SocialMediaType,
} from '../../models/enums/business';
import {EGeneralColors} from '../../models/enums/colors';
import {HH_MM_A_FORMAT} from '../../constants/dates';
import {IBusinessData, IBusinessHours} from '../../models/api/business';
import {IBusinessTranslation} from '../../models/interfaces/categories';
import {Tabs, TabList, TabPanel} from 'react-tabs';
import {UserConfirmationRequestState} from '../../models/enums/userConfirmationRequestState';
import {UserRoles} from '../../models/enums/userRoles';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DefaultBusinessImages from '../../assets/images/DefaultBusinessImage.png';
import DefaultCoverImage from '../../assets/images/DefaultCoverPicture.png';
import DynamicTabs from '../DynamicTabs';
import ImagesVerification from '../ImagesVerification';
import LinkButton from '../LinkButton';
import LocationMap from '../LocationMap';
import ProfileVerificationPanel from '../ProfileVerificationPanel';
import ProfileVerificationPanelTab from '../ProfileVerificationPanelTab';
import RoundedButton from '../RoundedButton';
import useBusinessHours from '../../hooks/useBusinessHours';
import useRoleData from '../../hooks/useRoleData';
import './styles.scss';
import 'react-tabs/style/react-tabs.css';

interface IProps {
  data: IBusinessData;
  onConfirmationRequired: (status: UserConfirmationRequestState) => void;
  closeModal: () => void;
}

const ProfileEditAuthorization = ({
  data,
  onConfirmationRequired,
  closeModal,
}: IProps) => {
  // Hooks
  const {t, i18n} = useTranslation('taskManagement');
  const {getCorrectTime, sortBusinessHours, unifyBusinessHours} =
    useBusinessHours();
  const {fullText, cssClass} = useRoleData(
    data.businessVendors[0]?.mainVendor
      ? UserRoles.manaVendor
      : UserRoles.vendor,
  );
  const {selected} = useSelector((state: AppState) => state.tasks);

  // States
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexLocations, setTabIndexLocations] = useState(0);

  const buildBusinesInformation = () => {
    return [
      {
        label: t('businessName'),
        value: data.name || '',
      },
      {
        label: t('businessDescription'),
        value: data.description || '',
      },
      {
        label: t('businessCategory'),
        value: getCategoryName(),
      },
    ];
  };

  const getCategoryName = () => {
    const category = selected?.subCategories[0].translations?.filter(
      (sc: IBusinessTranslation) =>
        sc.language.languageCode === i18n.resolvedLanguage,
    );
    return category ? category[0].value : '';
  };

  const buildContactInformation = () => {
    let facebookLink = '';
    let instagramLink = '';
    let twitterLink = '';
    console.log(data.businessSocialMedia);
    data.businessSocialMedia.map(d => {
      if (d.socialMediaType === SocialMediaType.Facebook) {
        facebookLink = d.link;
      } else if (d.socialMediaType === SocialMediaType.Instagram) {
        instagramLink = d.link;
      } else if (d.socialMediaType === SocialMediaType.Twitter) {
        twitterLink = d.link;
      }
    });

    return [
      {
        label: t('tabsModals:fields:fieldFirstName'),
        value: data.businessContactPerson?.name,
      },
      {
        label: t('tabsModals:fields:fieldLastName'),
        value: data.businessContactPerson?.lastname,
      },
      {
        label: t('tabsModals:fields:fieldContactEmail'),
        value: data.businessContactPerson.email,
        customCssClass: ECssClassBuildData.Email,
      },
      {
        label: t('tabsModals:fields:fieldWebsiteUrl'),
        value: data.businessContactPerson.contactWebSiteUrl,
      },
      {
        label: t('tabsModals:fields:fieldPhoneNumber'),
        value: `${data.businessContactPerson.phoneNumberCountryCode} ${data.businessContactPerson.phoneNumber}`,
      },
      {
        label: t('tabsModals:fields:fieldTwitter'),
        value: twitterLink,
      },
      {
        label: t('tabsModals:fields:fieldFacebook'),
        value: facebookLink,
      },
      {
        label: t('tabsModals:fields:fieldInstagram'),
        value: instagramLink,
      },
    ];
  };

  const buildImages = () => {
    return [
      {
        label: '',
        value: (
          <ImagesVerification
            bannerImage={data.coverImageUrl || DefaultCoverImage}
            images={data.files}
            profileImage={data.profileImageUrl || DefaultBusinessImages}
          />
        ),
      },
    ];
  };

  const buildLocations = () => {
    const sortObject = data.locations.sort(
      (a: any, b: any) => b.primary - a.primary,
    );
    const baseData = [
      {
        label: t('tabsModals:fields:fieldNickName'),
        value: sortObject[tabIndexLocations].nickName || '',
      },
      {
        label: t('addressLine1'),
        value: sortObject[tabIndexLocations].location.streetAddress || '',
      },
      {
        label: t('addressLine2'),
        value: sortObject[tabIndexLocations]?.location.apartmentSuit || '',
      },
      {
        label: t('country'),
        value: sortObject[tabIndexLocations].location.country || '',
      },
      {
        label: t('state'),
        value: sortObject[tabIndexLocations].location.state || '',
      },
      {
        label: t('city'),
        value: sortObject[tabIndexLocations].location.cityName || '',
      },
      {
        label: t('zipCode'),
        value: sortObject[tabIndexLocations].location.zipCode || '',
      },
      {
        label: '',
        value: (
          <LocationMap
            center={{
              lat: Number(sortObject[tabIndexLocations].location.latitude) || 0,
              lng:
                Number(sortObject[tabIndexLocations].location.longitude) || 0,
            }}
          />
        ),
        customCssClass: ECssClassBuildData.Community,
      },
    ];

    return baseData;
  };

  const buildHours = () => {
    return [
      {
        label: t('tabsModals:fields:fieldSunday'),
        value: getTimerangeDay(EWeekdays.Sunday),
      },
      {
        label: t('tabsModals:fields:fieldMonday'),
        value: getTimerangeDay(EWeekdays.Monday),
      },
      {
        label: t('tabsModals:fields:fieldTuesday'),
        value: getTimerangeDay(EWeekdays.Tuesday),
      },
      {
        label: t('tabsModals:fields:fieldWednesday'),
        value: getTimerangeDay(EWeekdays.Wednesday),
      },
      {
        label: t('tabsModals:fields:fieldThursday'),
        value: getTimerangeDay(EWeekdays.Thursday),
      },
      {
        label: t('tabsModals:fields:fieldFriday'),
        value: getTimerangeDay(EWeekdays.Friday),
      },
      {
        label: t('tabsModals:fields:fieldSaturday'),
        value: getTimerangeDay(EWeekdays.Saturday),
      },
    ];
  };

  const getTimerangeDay = (day: EWeekdays) => {
    const businessHours: IBusinessHours[] = data.businessHours;

    if (businessHours.length) {
      const unifyBusinessHoursFormat = unifyBusinessHours(businessHours);

      const filterTimerangesDay = unifyBusinessHoursFormat.filter(
        (e: IBusinessHours) => e.weekDay === day,
      );

      if (filterTimerangesDay.length) {
        const [first, second] = sortBusinessHours(
          filterTimerangesDay,
          'desc',
          'id',
        );

        const firstTimerange = `${getCorrectTime(
          first.openTime,
          HH_MM_A_FORMAT,
        )} - ${getCorrectTime(first.closeTime, HH_MM_A_FORMAT)}`;

        const secondTimerange = second
          ? `, ${getCorrectTime(
              second.openTime,
              HH_MM_A_FORMAT,
            )} - ${getCorrectTime(second.closeTime, HH_MM_A_FORMAT)}`
          : '';

        return `${firstTimerange}${secondTimerange}`;
      }
      return t('global:closed');
    }
    return '';
  };

  return (
    <div className="profile-verification">
      <div className="top-container">
        <div className="close-button" onClick={closeModal} />
      </div>

      <div className="verification-header">
        <div>
          <span className="title">
            {t('verifyBusinessTitle')}
            <span className="middot">&middot;</span>
            <span className={`role-name ${cssClass}`}>{fullText}</span>
          </span>
        </div>
        <span className="description">{t('verifyAccountDescription')}</span>
      </div>

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className="tab-list">
          <ProfileVerificationPanelTab isSelected={tabIndex === 0}>
            {t('tabsModals:tabs:tabBasicInfo')}
          </ProfileVerificationPanelTab>

          <ProfileVerificationPanelTab isSelected={tabIndex === 1}>
            {t('tabsModals:tabs:tabContactInfo')}
          </ProfileVerificationPanelTab>

          <ProfileVerificationPanelTab isSelected={tabIndex === 2}>
            {t('tabsModals:tabs:tabImages')}
          </ProfileVerificationPanelTab>

          <ProfileVerificationPanelTab isSelected={tabIndex === 3}>
            {t('tabsModals:tabs:tabLocations')}
          </ProfileVerificationPanelTab>

          <ProfileVerificationPanelTab isSelected={tabIndex === 4}>
            {t('tabsModals:tabs:tabBusinessHours')}
          </ProfileVerificationPanelTab>
        </TabList>

        <TabPanel>
          <ProfileVerificationPanel
            className="panel"
            data={buildBusinesInformation()}
            title={t('tabsModals:titles:titleBasicInfo')}
          />
        </TabPanel>

        <TabPanel>
          <ProfileVerificationPanel
            className="panel"
            data={buildContactInformation()}
            title={t('tabsModals:titles:titleBasicInfo')}
          />
        </TabPanel>

        <TabPanel>
          <ProfileVerificationPanel className="panel" data={buildImages()} />
        </TabPanel>

        <TabPanel>
          <ProfileVerificationPanel
            className="panel"
            data={buildLocations()}
            title={
              <DynamicTabs
                data={data.locations}
                generalTabsText={t('global:location')}
                propertyOrderObject={'primary'}
                mainTabText={t('global:mainLocation')}
                tabIndex={tabIndexLocations}
                title={t('tabsModals:titles:titleLocations')}
                setTabIndex={setTabIndexLocations}
              />
            }
          />
        </TabPanel>

        <TabPanel>
          <ProfileVerificationPanel
            className="panel"
            data={buildHours()}
            title={t('tabsModals:titles:titleHours')}
          />
        </TabPanel>
      </Tabs>

      <div className="verification-footer">
        <LinkButton
          className="side start"
          label={'Cancel'}
          onClick={closeModal}
          color={EGeneralColors.colorBlueFilter}
        />
        <div className="side end">
          <RoundedButton
            color="red-outlined"
            label={t('global:rejectBusiness')}
            onClick={() =>
              onConfirmationRequired(UserConfirmationRequestState.Declined)
            }
            style={{
              marginRight: 20,
              height: 40,
              width: 160,
            }}
          />
          <RoundedButton
            color="blue"
            label={t('approveButton')}
            onClick={() =>
              onConfirmationRequired(UserConfirmationRequestState.Approved)
            }
            style={{
              height: 40,
              width: 160,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileEditAuthorization;
