import React from 'react';
import {EDropDownItemsTextColor, ETypeActions} from '../models/enums/generals';
import {ErrorCodes} from '../models/enums/errorCodes';
import {getTypeStatusTag} from '../constants/getTexts';
import {IDropDownTableActions} from '../models/interfaces/generals';
import {ISelectOptions} from '../models/interfaces/forms';
import {RootState} from '../redux';
import {showPopup} from '../redux/actions/uiActions';
import {t} from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState, useCallback} from 'react';
import {
  ICreateUserBodyRequest,
  IEditUserRoleBodyRequest,
} from '../models/api/usersAdmin';
import {
  IUsersBuildedTableData,
  IUserListResult,
} from '../models/api/usersAdmin';
import {
  clearUserDataState,
  clearUserState,
  createNewAdminUser,
  deleteAdminUser,
  editRoleAdminUser,
  getAndSaveUsersList,
  setDataAdminUser,
  setUserListSearch,
} from '../redux/actions/userAdminActions';
import Tag from '../components/Tag';
import TableActions from '../components/TableActions';

interface IUseUsersAdminList {
  adminRolesOptions: ISelectOptions[] | undefined;
  buildedData: IUsersBuildedTableData[];
  handleClearUserData: () => void;
  handleCreateNew: (data: ICreateUserBodyRequest) => void;
  handleDelete: (id: string) => void;
  handleEdit: (data: IEditUserRoleBodyRequest) => void;
  onSearchFilter: (val: any) => void;
  searchFilter: string;
  showConfirmationModal: boolean;
  showModal: boolean;
  adminUserId: string;
  setShowConfirmationModal: (show: boolean) => void;
  setShowModal: (show: boolean) => void;
  setTypeAction: (action: ETypeActions) => void;
  typeAction: ETypeActions;
}

const useAdminUsersList = (): IUseUsersAdminList => {
  // Hooks
  const {userAdmin, user} = useSelector((state: RootState) => state);
  const {results, search, errorCode} = userAdmin;
  const dispatch = useDispatch();

  // Local State
  const [adminRolesOptions, setAdminRolesOptions] =
    useState<ISelectOptions[]>();
  const [adminUserId, setAdminUserId] = useState('');
  const [buildedData, setBuildedData] = useState<IUsersBuildedTableData[]>([]);
  const [list, setList] = useState<IUserListResult[]>([]);
  const [searchFilter, setSearchFilter] = useState(search);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeAction, setTypeAction] = useState<ETypeActions>(
    ETypeActions.Create,
  );

  //Effects
  useEffect(() => {
    dispatch(clearUserState());
    handleBuildOptions();
  }, []);

  useEffect(() => {
    handleGetList();
  }, [searchFilter]);

  useEffect(() => {
    if (results.length) {
      setList(results);
    }
  }, [results]);

  useEffect(() => {
    handleSetTableData();
  }, [list]);

  useEffect(() => {
    if (errorCode === ErrorCodes.noError) {
      dispatch(clearUserDataState());
      handleGetList();
    }
  }, [errorCode]);

  // Handlers - Functions
  const handleGetList = () => {
    dispatch(
      getAndSaveUsersList({
        nameAndEmailSearch: searchFilter,
      }),
    );
  };

  const handleSearch = (search: string) => {
    setSearchFilter(search);
    dispatch(setUserListSearch(search));
  };

  const handleCreateNew = (data: ICreateUserBodyRequest) => {
    dispatch(createNewAdminUser(data));
  };

  const handleEdit = (data: IEditUserRoleBodyRequest) => {
    dispatch(editRoleAdminUser(data));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteAdminUser(id));
    setShowConfirmationModal(false);
  };

  const handleOpenModalForm = (id: string, type: ETypeActions) => {
    handleClearUserData();
    const findUserData = list.find(user => user.id === id);

    if (findUserData) {
      const {id, lastname, name, roles, email} = findUserData;
      dispatch(
        setDataAdminUser({
          id,
          lastName: lastname,
          name,
          roleId: roles[0].id,
          username: email,
        }),
      );
      setTypeAction(type);
      setShowModal(true);
    } else {
      dispatch(showPopup(t('adminUsers:errorMessage'), 3000, 'error'));
    }
  };

  const handleSetTableData = () => {
    const buildData: IUsersBuildedTableData[] = list.map(user => {
      const statusData = getTypeStatusTag(user.status);
      const actionsData: IDropDownTableActions[] = [
        {
          text: t('global:view'),
          onClick: () => handleOpenModalForm(user.id, ETypeActions.View),
        },
        {
          text: t('global:edit'),
          onClick: () => handleOpenModalForm(user.id, ETypeActions.Edit),
        },
        {
          color: EDropDownItemsTextColor.Red,
          text: t('global:delete'),
          onClick: () => {
            setAdminUserId(user.id);
            setShowConfirmationModal(true);
          },
        },
      ];

      return {
        actions: <TableActions actionsData={actionsData} />,
        email: user.email,
        name: user.name,
        role: user.roles[0].displayName,
        status: (
          <Tag
            backgroundColor={statusData.background}
            color={statusData.textcolor}
            text={statusData.text}
          />
        ),
      };
    });
    setBuildedData(buildData);
  };

  const handleClearUserData = () => {
    dispatch(clearUserDataState());
  };

  const handleBuildOptions = () => {
    if (user.adminRoles?.length) {
      const options = user.adminRoles.map(role => {
        const object: ISelectOptions = {
          label: role.displayName,
          value: role.id,
        };
        return object;
      });
      setAdminRolesOptions(options);
    }
  };

  const onSearchFilter = useCallback((e: any) => {
    handleSearch(e.target.value);
  }, []);

  return {
    adminRolesOptions,
    adminUserId,
    buildedData,
    handleClearUserData,
    handleCreateNew,
    handleDelete,
    handleEdit,
    onSearchFilter,
    searchFilter,
    setShowConfirmationModal,
    setShowModal,
    setTypeAction,
    showConfirmationModal,
    showModal,
    typeAction,
  };
};

export default useAdminUsersList;
