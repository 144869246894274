import React from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ImageIcon} from '../../assets/icons/image-icon.svg';
import './styles.scss';

type IProps = {
  onDrop: any;
  acceptVideo?: boolean;
};

const Dropzone: React.FC<IProps> = ({onDrop, acceptVideo = true}) => {
  // Hooks
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: acceptVideo
      ? {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
          'video/*': ['.mp4'],
        }
      : {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
        },
    maxFiles: 1,
    multiple: false,
  });
  const {t} = useTranslation();

  return (
    <div className="dropzone-div" {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      {isDragActive ? (
        <p className="dropzone-content">{t('global:dropFileRelease')}</p>
      ) : (
        <div className="dropzone-content">
          <div className="image-icon">
            <ImageIcon />
          </div>
          <div className="text-description">
            <span className="select-word">{t('global:select')}</span>
            &nbsp;
            {t('global:dropFileSelect')}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
