import {combineReducers} from 'redux';
import {commentReducers} from './commentReducers';
import authReducer from './authReducer';
import eventReducer from './eventReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import postReducer from './postReducer';
import reportReducer from './reportReducer';
import taskReducer from './taskReducer';
import tierReducer from './tierReducer';
import uiReducer from './uiReducer';
import userAdminReducer from './userAdminReducer';
import userReducer from './userReducer';
import communityCityReducer from './communityCityReducers';
import membershipsReducer from './membershipsReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  comments: commentReducers,
  event: eventReducer,
  forgotPassword: forgotPasswordReducer,
  post: postReducer,
  reports: reportReducer,
  tasks: taskReducer,
  tiers: tierReducer,
  ui: uiReducer,
  user: userReducer,
  userAdmin: userAdminReducer,
  communityCity: communityCityReducer,
  memberships: membershipsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
