import {capitalizeFirstLetter} from './capitalizeFirstLetter';

export const parsePublishPostToFormData = (body: any): FormData => {
  const data = new FormData();

  Object.keys(body).map(key => {
    data.append(capitalizeFirstLetter(key), body[key]);
  });

  return data;
};
