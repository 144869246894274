import {
  IMembershipsBodyRequest,
  IMembershipsResponse,
} from '../../models/api/memberships';
import {getMembershipsList} from '../../services/api/membershipServices';
import {RootState} from '../reducers';
import {
  CLEAR_MEMBERSHIPS_LIST,
  MembershipsActionsTypes,
  SET_MEMBERSHIPS_LIST,
  SET_MEMBERSHIPS_LOADING,
} from '../types/membershipsTypes';

export const setMembershipsList = (
  payload: IMembershipsResponse,
): MembershipsActionsTypes => ({
  type: SET_MEMBERSHIPS_LIST,
  payload,
});

export const clearMembershipsList = (): MembershipsActionsTypes => ({
  type: CLEAR_MEMBERSHIPS_LIST,
});

export const setMembershipsLoading = (
  payload: boolean,
): MembershipsActionsTypes => ({
  type: SET_MEMBERSHIPS_LOADING,
  payload,
});

export const getAndSaveMembershipList =
  (data: IMembershipsBodyRequest) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const {
        memberships: {isLoading},
      } = getState();

      if (isLoading) {
        return;
      }

      dispatch(setMembershipsLoading(true));

      const response = await getMembershipsList(data);

      console.log('response', response);

      dispatch(setMembershipsList(response.data));
    } catch (err) {
      dispatch(setMembershipsLoading(false));
      console.log('Error: Fail on get memberships: ', err);
    }
  };

export const updateUser =
  (userId: string, membership: number) =>
  async (dispatch: any, getState: () => RootState) => {
    const {memberships} = getState();

    const mappedData = memberships.results.map(u => {
      if (u.id === userId) {
        return {...u, membership};
      } else {
        return u;
      }
    });

    dispatch(setMembershipsList({...memberships, results: mappedData}));
  };
