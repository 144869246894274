export enum EMenuOptions {
  Dashboard = '/dashboard',
  Events = '/events',
  ForgotPassword = '/forgot-password',
  ManaNews = '/mana-news',
  NewPassword = '/new-password',
  Reports = '/reports',
  Root = '/',
  TaskManager = '/task-manager',
  Tiers = '/tiers',
  Users = '/users',
  VerificationCode = '/verification-code',
  Cities = '/cities',
  Communities = '/communities',
  Memberships = '/memberships',
}

export enum EMenuToggle {
  Open = 'open',
  Closed = 'closed',
}
