import {EMenuOptions} from '../models/enums/menu';
import {IMenuOptions} from '../models/interfaces/menuOptions';
import Dashboard from '../assets/icons/menu-dashboard.svg';
import Events from '../assets/icons/star.svg';
import News from '../assets/icons/menu-news.svg';
import Reports from '../assets/icons/menu-reports.svg';
import TaskManager from '../assets/icons/menu-task-manager.svg';
import Tiers from '../assets/icons/menu-tiers.svg';
import Users from '../assets/icons/menu-users.svg';
import Vip from '../assets/icons/vip.svg';

export const menuItems: IMenuOptions[] = [
  {
    label: 'menu:dashboard',
    icon: Dashboard,
    url: EMenuOptions.Dashboard,
    showItem: true,
  },
  {
    label: 'menu:taskmanager',
    icon: TaskManager,
    url: EMenuOptions.TaskManager,
    showItem: true,
  },
  {
    label: 'menu:reports',
    icon: Reports,
    url: EMenuOptions.Reports,
    showItem: true,
  },
  {
    label: 'menu:tiers',
    icon: Tiers,
    url: EMenuOptions.Tiers,
    showItem: true,
  },
  {
    label: 'menu:manaNews',
    icon: News,
    url: EMenuOptions.ManaNews,
    showItem: true,
  },
  {
    label: 'menu:events',
    icon: Events,
    url: EMenuOptions.Events,
    showItem: true,
  },
  {
    label: 'menu:users',
    icon: Users,
    url: EMenuOptions.Users,
    showItem: true,
  },
  {
    label: 'menu:cities',
    icon: Users,
    url: EMenuOptions.Cities,
    showItem: true,
  },
  {
    label: 'menu:communities',
    icon: Users,
    url: EMenuOptions.Communities,
    showItem: true,
  },
  {
    label: 'menu:memberships',
    icon: Vip,
    url: EMenuOptions.Memberships,
    showItem: true,
  },
];
